$primary: #1f2041;
$secondary: #2cab6c;
$ternary: #f7fcfe;
// $quaternary: #b6d82f;
// $quintile: #528a9c;
// $senary: #ef4640;

// $pagination: #623581;

// $social-icons: #f4c00a;

// $green: $secondary;

// $orange: $primary;
// $yellow: $ternary;
// $purple: $quaternary;
// $blue: $quintile;
// $red: $senary;

// $success: $quintile;
// $danger: $primary;
// $text-success: $quintile;
// $text-danger:  $primary;

$grey: #454661;
$grey-alt: #747588;
// $dark-grey: #3e3938;
$darkest-grey: #212121;
$light-grey: #a1a2af;
$lighter-grey: #e8e9f3;
$lightest-grey: #f5f5f5;
$off-white: #f7fcfe;

$light: #fff;
$dark: $darkest-grey;

// #e4f5fe light blue

// $hover: #5db77a;
// $active: #006C4C;

$instagram: #e1306c; // #f00075
$facebook: #3b5998;
$twitter: #1da1f2;
$pinterest: #bd081c;
$youtube: #f00;
$vimeo: #1ab7ea;
$linkedin: #007bb5;

// $cswr-blue: #6ba9d8;
// $cswr-driftwood: #b37e4a;
// $cswr-green: #92b361;
// $cswr-orange: #c45c2a;
// $cswr-purple: #6b4576;
// $cswr-rajah: #f7af63;
// $cswr-red: #e38b90;
// $cswr-sienna: #d58554;
// $cswr-yellow: #f6af31;

// .banner__item.blue,
// .drop-shadow-blue:before,
// .product-tile.blue:before,
// .product-header.blue:before {
//   background-color: $joypots-blue !important;
// }
