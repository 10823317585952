.mini-cta {
  background: $off-white;
  outline: 1px solid hotpink;

  i {
    @include font-size(24px);
  }

  h6 {
    font-family: "Avenir LT W01_85 Heavy1475544", $font-family-sans-serif;
  }

  p {
    @include font-size(18px);
    color: $grey-alt;
    font-family: "Avenir LT W01_65 Medium1475532";
  }
}
