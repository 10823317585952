@import './login';

#register {
  margin-bottom: 88px;
}

.form-section-heading {
  border-bottom: 1px solid $lighter-grey;
  color: $secondary;
}

label {
  font-family: $input-font-family;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  margin-bottom: 0.25em;
}

.custom-control-label {
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  color: $grey;
  // font-size: 14px;
  // font-weight: 400;
  padding-left: 0.5rem;
}

.custom-select {
  cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.is-error {
  .custom-control-label {
    background: #fff;
    padding: 0.5rem;
  }

  input,
  textarea,
  select {
    border: 2px solid #ff424e !important;
    color: #ff424e !important;
    outline: 1px solid #ff424e !important;
  }

  input[type='checkbox'],
  input[type='radio'] {
    + label {
      &:before {
        border-color: #ff424e;
      }
    }
  }

  //&.input { // only on inputs, not form
  .input-message {
    @include font-size(14px);
    color: #ff424e;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    position: relative;
    visibility: visible;
    width: 100%;
    z-index: 1;
  }
  //}
}

.alert {
  padding: 35px;

  p {
    font-size: 18px;
    margin-bottom: 0;

    &:only-of-type {
      margin-bottom: 0;
    }
  }
}

.alert--danger {
  @extend .alert-danger;
  // background: #fff;
  border: 3px solid #ff424e;
  color: $grey;

  a {
    color: #ff424e;
  }
}

.alert--success {
  @extend .alert-success;
  background: #f4fbf7;
  border: 3px solid #9cde9f;
  color: $grey;

  p {
    font-size: 18px;

    &:only-of-type {
      margin-bottom: 0;
    }
  }
}

.alert--dashboard {
  display: flex;

  i {
    color: #2cab6c;
    font-size: 24px;
    margin-right: 30px;
    margin-top: 4px;
  }
}

.submit-btn.waiting {
  display: none;
}

.gp_form.waiting {
  .submit-btn {
    display: none;
  }

  .spinner,
  .spinner-border {
    display: inline-block;
  }
}

label small {
  display: inline-block;
  margin-top: 0.5rem;
}

.city-town-row {
  display: none;
}

.form-control {
  margin-bottom: 15px;
  box-shadow: none;
  height: calc(1.5em + 1.5rem + 4px);

  // &:invalid {
  //   border-color: red;
  // }

  // &:placeholder-shown {
  //   border-color: $input-border-color;
  // }
}

.was-validated input[type="checkbox"]:invalid {
  + label, ~ div {
    color: #dc3545;
    border-color: #dc3545;
  }
}

.section {
  border-bottom: 1px solid #e8e9f3;
  margin-bottom: 25px;

  h3 {
    font-size: 24px;
    margin-bottom: 16px;
    margin-top: 0;
  }
}

.form-control-container {
  display: flex;
  > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    > .form-control {
      margin-top: auto;
    }

    > .form-control--confirm_password {
      margin-top: 0;
    }
  }
}

select.form-control {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/app/themes/gpcswr/src/img/select.png);
  background-repeat: no-repeat;
  background-position: right 15px center;
}
