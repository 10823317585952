.circle-block {
  margin-bottom: 50px;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.circle {
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 516px;
  position: relative;
  // top: -38%;
  // transform: translateY(-38%);
  // width: 100%;
  z-index: 4;

  @include media-breakpoint-up(md) {
    margin-bottom: 138px;
  }
}

.circle__inner {
  background-color: #e4f5fe;
  border-radius: 30px;
  padding: 30px;
  position: relative;

  @include media-breakpoint-up(md) {
    border-radius: 50%;
    padding: 0;
    padding-bottom: 100%;
  }
}

.circle__text {
  text-align: center;
  // transform: translateY(-50%) translateX(-50%);
  width: 100%;

  @include media-breakpoint-up(md) {
    position: absolute;
    left: 50%;
    max-width: 353px;
    top: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0.75);
  }

  @include media-breakpoint-up(lg) {
    max-width: 353px;
    transform: translateY(-50%) translateX(-50%);
  }

  h3 {
    color: $primary;
  }

  p {
    @include font-size(20px);
    color: $primary;
    font-family: 'Avenir LT W01_45 Book1475508', $font-family-sans-serif;
    font-weight: 350;
  }
}

.banner + .circle-block {
  @media (min-height: 812px), (max-width: 992px) {
    margin-top: -60px;
  }

  @media (min-width: 992px) {
    margin-top: -180px;
  }
}
