.sidebar {
  background: #1f2041;
  height: 100vh;
  flex-shrink: 0;
  max-width: 45px;
  // overflow: hidden;
  padding: 21px 21px 30px 4px;
  position: fixed;
  top: 0;
  transition: all 0.3s;
  transform: translateX(-45px);
  z-index: 2;

  &.is-open {
    max-width: 100vw;
    padding: 21px 21px 30px 46px;
    transform: translateX(0);

    .logo-text-group,
    .logo-text-site,
    li {
      // display: block;
      opacity: 1;
      transform: translateX(0);
    }

    @media (min-width: 768px) and (max-width: 1600px) {
      width: 500px;
      max-width: 500px;
    }

    @media (min-width: 1600px) {
      max-width: 315px;
    }
  }

  @media (min-width: 1600px) {
    max-width: 315px;
    padding: 21px 21px 30px 46px;
    position: sticky;
    top: 0;
    transform: translateX(0);
  }

  .logo-text-group {
    font-size: 15px;

    // display: none;
    opacity: 0;
    white-space: nowrap;
    // transform: translateX(-25px);
    transition: all 0.3s;

    @media (min-width: 1600px) {
      display: block;
      opacity: 1;
      transform: translateX(0);
    }
  }

  .logo-text-site {
    font-size: 18px;

    // display: none;
    opacity: 0;
    white-space: nowrap;
    // transform: translateX(-25px);
    transition: all 0.3s;

    @media (min-width: 1600px) {
      display: block;
      opacity: 1;
      transform: translateX(0);
    }
  }

  a {
    white-space: nowrap;
  }
}

.sidebar__logo {
  display: flex;
  margin-bottom: 53px;
  margin-top: 30px;

  @media (min-width: 1600px) {
    margin-top: 0;
  }

  img {
    max-width: none;

    width: 40px;
  }
}

.sidebar__nav {
  li {
    opacity: 0;
    transition: all 0.3s;
    width: 100%;

    @media (min-width: 1600px) {
      opacity: 1;
    }
  }

  a {
    color: white;

    i {
      width: 25px;
      font-size: 20px;
      margin-right: 29px;
      transition: all 0.3s;
    }

    &:hover {
      i {
        color: $secondary;
        margin-right: 35px;
        transform: scale(1.15);
      }
    }
  }

  .nav-link {
    padding: 1rem 1rem 1rem 46px;
    margin-left: -46px;
    margin-right: -21px;

    // 21px 21px 30px 46px
    &.active {
      background-color: #454661;
    }
  }

  .return-link {
    color: $secondary;
  }

  pointer-events: none;

  .is-open & {
    pointer-events: auto;
  }

  @media (min-width: 1600px) {
    pointer-events: auto;
  }
}

.sidebar__menu-toggle {
  background-color: $primary;
  border: 0;
  height: 30px;
  left: 0;
  margin-left: 7px;
  position: absolute;
  padding: 0;
  top: 15px;
  transform: translateX(36px);
  width: 36px;

  @media (min-width: 1600px) {
    display: none;
  }

  &.menu-button {
    margin-bottom: auto;
    margin-top: auto;
    transform: none;
  }
}

.sidebar__menu-line {
  background-color: white;
  display: block;
  height: 2px;
  position: absolute;
  left: 7px;
  right: 7px;
  transition: all 0.3s;
  transform-origin: left;
  // width: 100%;

  &:nth-child(2) {
    top: 7px;

    .is-open & {
      transform: translateY(7px) rotate(-35deg) skewX(-35deg) scaleX(0.65);
    }
  }

  &:nth-child(3) {
    top: 50%;
    transform: translateY(-50%);
  }

  &:nth-child(4) {
    bottom: 7px;

    .is-open & {
      transform: translateY(-7px) rotate(35deg) skewX(35deg) scaleX(0.65);
    }
  }
}

.sidebar {
  .sidebar__menu-toggle {
    height: 66px;
    top: 0;
    width: 66px;

    .is-open & {
      background-color: transparent;
    }
  }

  &.is-open {
    .sidebar__menu-toggle {
      background-color: transparent;
      transition: all 0.3s;
      transform: translateX(20px);
    }
  }

  .sidebar__menu-line {
    left: calc(50% - 11px);
    width: 22px;

    &:nth-child(2) {
      top: 25px;
    }

    &:nth-child(4) {
      bottom: 25px;
    }
  }
}
