.breadcrumb {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.breadcrumb-item {
  font-family: 'Avenir LT W01_65 Medium1475532', $font-family-sans-serif;
  font-weight: 550;

  &:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
  }

  a {
    color: $light-grey;
    font-family: inherit;
  }
}
