/* custom bootstrap variables */

$body-bg: #fff;
$body-color: $darkest-grey;

// native fonts
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-base: 'Avenir LT W01_45 Book1475508', $font-family-sans-serif;

$enable-responsive-font-sizes: true;
$enable-rounded: true;
$enable-gradients: false;
$enable-shadows: false;
$enable-transitions: true;
$enable-grid-classes: true;
$enable-print-styles: true;
$enable-validation-icons: true;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'ternary': $ternary // "quaternary": $quaternary,
    // "quintile": $quintile,
    //"danger": $primary,,,,,,,,
);

$link-color: theme-color('secondary');
$link-hover-color: theme-color('primary');
$link-hover-decoration: none;

$line-height-base: 1.5; // 24px

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 3.875; // 62px
$h2-font-size: $font-size-base * 3.125; // 50px
$h3-font-size: $font-size-base * 2.375; // 38px
$h4-font-size: $font-size-base * 1.75; // 28px
$h5-font-size: $font-size-base * 1.5; // 24px
$h6-font-size: $font-size-base * 1.25; // 20px

$headings-margin-bottom: 1.125rem;
$headings-font-family: 'Avenir LT W01_85 Heavy1475544', $font-family-sans-serif;
$headings-font-weight: normal;
$headings-line-height: 1.2;
$headings-color: $secondary;

$border-color: rgba(#fff, 0.15);

$component-active-color: #fff;
$component-active-bg: theme-color('secondary');

$border-radius: 5px;
$border-radius-lg: 5px;
$border-radius-sm: 5px;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 2,
    ),
    6: (
      $spacer * 3,
    ),
    7: (
      $spacer * 4,
    ),
    8: (
      $spacer * 5,
    ),
    9: (
      $spacer * 6,
    ),
    10: (
      $spacer * 7,
    ),
  ),
  $spacers
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  fhd: 1900px,
  qhd: 2540px //uhd: 3840px,,,,,,,,
);

$container-max-widths: (
  sm: 540px,
  md: 726px,
  // 720
    lg: 960px,
  xl: 1140px,
  fhd: 1330px,
  qhd: 1600px // 2000px
    //uhd: 3000px,,,,,,,,
);

$grid-columns: 12;
$grid-gutter-width: 30px;

// Buttons

// $btn-padding-y:               $input-btn-padding-y !default;
// $btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family: 'Avenir LT W01_85 Heavy1475544', $font-family-sans-serif;
$btn-font-size: 1.25rem;
// $btn-line-height:             $input-btn-line-height !default;

// $btn-padding-y-sm:            $input-btn-padding-y-sm !default;
// $btn-padding-x-sm:            $input-btn-padding-x-sm !default;
// $btn-font-size-sm:            $input-btn-font-size-sm !default;
// $btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 3rem;
$btn-font-size-lg: 1.25rem;
// $btn-line-height-lg:          $input-btn-line-height-lg !default;

$btn-border-width: 2px;

$btn-font-weight: normal;
// $btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
// $btn-focus-width:             $input-btn-focus-width !default;
// $btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
// $btn-disabled-opacity:        .65 !default;
// $btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

// $btn-link-disabled-color:     $gray-600 !default;

// $btn-block-spacing-y:         .5rem !default;

// Navs

$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;

// Forms
$input-padding-y: 1rem;
$input-padding-x: 1rem;
$input-font-family: 'Avenir LT W01_45 Book1475508', $font-family-sans-serif;
$input-font-size: 18px;
$input-font-weight: normal;

$input-color: $primary;
$input-border-color: #e4f5fe;
$input-border-width: 2px;

$input-placeholder-color: rgba($primary, 0.75);

$custom-control-indicator-border-color: $secondary;

$custom-select-bg-size: 16px 20px;
$custom-select-indicator-color: $primary;
$custom-select-indicator: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);

// Breadcrumbs

$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-margin-bottom: 2rem;

$breadcrumb-bg: none;
$breadcrumb-divider-color: $light-grey;
$breadcrumb-active-color: $primary;
$breadcrumb-divider: '\f105';
//$breadcrumb-divider:              url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPjxwYXRoIGQ9Ik0yMjQuMyAyNzNsLTEzNiAxMzZjLTkuNCA5LjQtMjQuNiA5LjQtMzMuOSAwbC0yMi42LTIyLjZjLTkuNC05LjQtOS40LTI0LjYgMC0zMy45bDk2LjQtOTYuNC05Ni40LTk2LjRjLTkuNC05LjQtOS40LTI0LjYgMC0zMy45TDU0LjMgMTAzYzkuNC05LjQgMjQuNi05LjQgMzMuOSAwbDEzNiAxMzZjOS41IDkuNCA5LjUgMjQuNi4xIDM0eiIgZmlsbD0iI2ExYTJhZiIvPjwvc3ZnPg==);

// Dropdowns
// $dropdown-padding-y: 0;
// $dropdown-item-padding-y: 0.5rem;
// $dropdown-link-color: $primary;
// $dropdown-link-hover-color: $primary;
// $dropdown-link-hover-bg: #e6e6e6;

$form-feedback-icon-invalid: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" class="svg-inline--fa fa-exclamation-triangle fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="red" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>');
