.left-icons-cta,
.right-icons-cta {
  .media {
    .far,
    .fas,
    .fal {
      @include font-size(42px);
      color: $grey;
    }

    h5 {
      color: $secondary;
      font-family: "Avenir LT W01_65 Medium1475532", $font-family-sans-serif;
      font-weight: 550;
    }

    p {
      @include font-size(18px);
    }
  }
}
