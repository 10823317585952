.small-title {
  color: $primary;
  font-family: 'Avenir';
  font-size: 20px;
  font-weight: 500;
}

.small-title--with-icon {
  i {
    margin: 0 20px 0 0;
  }
}
