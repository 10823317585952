.login-reg-link {
  color: $secondary !important;
  font-family: 'Avenir LT W01_85 Heavy1475544', $font-family-sans-serif;
  font-size: 18px;
  // font-weight: 700;

  .fas {
    font-size: 20px;
    margin-right: 0.5rem;
    position: relative;
    top: 2px;
  }
}

.footer {
  .login-reg-link {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: $nav-link-padding-y;
    padding-bottom: $nav-link-padding-y;
    font-size: 19px;

    &:hover {
      color: $primary !important;
    }

    .fas {
      font-size: 24px;
    }
  }
}

.header-nav {
  background-color: $primary;
  display: flex;
  height: calc(100vh - 40px);
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 100%;
  transform: translateX(100%);
  transition: all 0.5s;
  width: 100vw;

  @include media-breakpoint-up(sm) {
    width: 400px;
  }

  @include media-breakpoint-up(lg) {
    background-color: transparent;
    height: auto;
    position: relative;
    right: auto;
    top: auto;
    transition: none;
    transform: none;
    width: auto;
  }

  &.is-open {
    transform: translateX(0);
    @include media-breakpoint-up(lg) {
    }
  }

  .nav {
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      margin-top: 0;
    }
  }

  .nav-item {
    background-color: $primary;
    border-radius: $border-radius;
    color: #fff;
    margin-left: 2px;
    margin-right: 2px;
    position: relative;
    // text-align: center;
    transition: background-color 300ms $material-decelerate,
      color 100ms $material-decelerate;

    @include media-breakpoint-up(lg) {
      text-align-last: left;
    }

    &:hover , &:focus, &:active {
      color: $secondary;
    }

    i {
      margin-right: 5px;
    }

    @include media-breakpoint-down(md) {
      i {
        order: 1;
        margin-right: 0;
        margin-left: 5px;
      }
    }

    .nav-link {
      @include static-font-size(20px);
      color: inherit;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'Avenir LT W01_85 Heavy1475544', $font-family-sans-serif;
      font-weight: 700;
      // line-height: (48px / $font-size-base) * 1rem;
      text-decoration: none;
      width: 100%;

      @include media-breakpoint-up(sm) {
        // font-size: 3vw;
      }

      @include media-breakpoint-up(lg) {
        @include static-font-size(18px);
      }

      &.active {
        color: #9fbfea;
      }

      &:hover , &:focus, &:active {
        color: $secondary;
      }
    }
  }

  .login-reg-link {
    padding-left: $nav-link-padding-x;
    padding-right: $nav-link-padding-x;
    padding-top: $nav-link-padding-y;
    padding-bottom: $nav-link-padding-y;
  }
}
