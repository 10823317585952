.phone-numbers {
  display: flex;
  flex-direction: column;

  h3 {
    color: $primary;
    font-size: 28px;
  }
}

.phone-number {
  background-color: $ternary;
  padding: 40px 58px 30px;
  margin-bottom: 33px;

  i {
    color: $primary;
    font-size: 24px;
  }

  h4,
  a,
  p {
    width: 100%;
  }

  h4 {
    color: $primary;
    display: inline-block;
    font-size: 24px;
    margin-bottom: 11px;
  }

  a {
    color: $secondary;
    display: inline-block;
    font-size: 20px;
    margin-bottom: 16px;
  }

  p {
    color: $grey-alt;
    font-size: 18px;
  }
}
