.footer {
  background: #fff;
  border-top: 1px solid $lighter-grey;
  color: $primary;
  flex-shrink: 0;

  .nav-item {
    @media (max-width: 991px) {
      width: 100%;
    }

    .nav-link {
      @include font-size(19px);
      color: $primary;
      font-family: 'Avenir LT W01_85 Heavy1475544', $font-family-sans-serif;
      font-weight: normal;
      // font-weight: 700;
      // line-height: (20px / $font-size-base) * 1rem;
      padding-left: 5px;
      padding-right: 5px;
      text-decoration: none;

      &:hover {
        color: $secondary;
      }
    }
  }
}

.footer-legal {
  flex-shrink: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;

  p {
    color: $primary;
    font-family: 'Avenir';
    font-size: 15px;
    font-weight: normal;
  }

  &:before {
    // background-color: $off-white;
    background-color: $lightest-grey;
    bottom: 0;
    content: '';
    display: block;
    //left: 0;
    position: absolute;
    //right: 0;
    top: 0;
    //width: 100vw;
    width: 100vw;
  }

  a {
    color: $primary;
    display: block;
    font-family: 'Avenir';
    font-size: 15px;
    font-weight: normal;
    padding: 0;
  }

  .nav-item {
    display: flex;
    position: relative;
    text-transform: uppercase;

    &::after {
      content: '|';
      margin: -2px 8px 0 8px;
    }

    &:nth-last-child(1)::after {
      display: none;
    }

    a {
      &:hover {
        color: $secondary;
      }

      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.footer--dashboard {
  margin-top: 55px;
}

.footer-legal--dashboard {
  margin-bottom: 24px;

  &::before {
    display: none;
  }

  a {
    font-size: 14px;
  }

  p {
    font-size: 13px;
  }
}
