@charset "UTF-8";
/* custom bootstrap variables */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #1f2041;
  --secondary: #2cab6c;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #fff;
  --dark: #212121;
  --ternary: #f7fcfe;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-fhd: 1900px;
  --breakpoint-qhd: 2540px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Avenir LT W01_45 Book1475508", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212121;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.125rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2cab6c;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1f2041;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.125rem;
  font-family: "Avenir LT W01_85 Heavy1475544", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  line-height: 1.2;
  color: #2cab6c; }

h1, .h1 {
  font-size: 3.875rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.5125rem + 3.15vw) ; } }

h2, .h2 {
  font-size: 3.125rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.4375rem + 2.25vw) ; } }

h3, .h3 {
  font-size: 2.375rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.3625rem + 1.35vw) ; } }

h4, .h4 {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.3rem + 0.6vw) ; } }

h5, .h5 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    h5, .h5 {
      font-size: calc(1.275rem + 0.3vw) ; } }

h6, .h6 {
  font-size: 1.25rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

/*!
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 726px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 1900px) {
    .container {
      max-width: 1330px; } }
  @media (min-width: 2540px) {
    .container {
      max-width: 1600px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-fhd-1, .col-fhd-2, .col-fhd-3, .col-fhd-4, .col-fhd-5, .col-fhd-6, .col-fhd-7, .col-fhd-8, .col-fhd-9, .col-fhd-10, .col-fhd-11, .col-fhd-12, .col-fhd,
.col-fhd-auto, .col-qhd-1, .col-qhd-2, .col-qhd-3, .col-qhd-4, .col-qhd-5, .col-qhd-6, .col-qhd-7, .col-qhd-8, .col-qhd-9, .col-qhd-10, .col-qhd-11, .col-qhd-12, .col-qhd,
.col-qhd-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1900px) {
  .col-fhd {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-fhd-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-fhd-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-fhd-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-fhd-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-fhd-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-fhd-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-fhd-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-fhd-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-fhd-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-fhd-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-fhd-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-fhd-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-fhd-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-fhd-first {
    order: -1; }
  .order-fhd-last {
    order: 13; }
  .order-fhd-0 {
    order: 0; }
  .order-fhd-1 {
    order: 1; }
  .order-fhd-2 {
    order: 2; }
  .order-fhd-3 {
    order: 3; }
  .order-fhd-4 {
    order: 4; }
  .order-fhd-5 {
    order: 5; }
  .order-fhd-6 {
    order: 6; }
  .order-fhd-7 {
    order: 7; }
  .order-fhd-8 {
    order: 8; }
  .order-fhd-9 {
    order: 9; }
  .order-fhd-10 {
    order: 10; }
  .order-fhd-11 {
    order: 11; }
  .order-fhd-12 {
    order: 12; }
  .offset-fhd-0 {
    margin-left: 0; }
  .offset-fhd-1 {
    margin-left: 8.33333%; }
  .offset-fhd-2 {
    margin-left: 16.66667%; }
  .offset-fhd-3 {
    margin-left: 25%; }
  .offset-fhd-4 {
    margin-left: 33.33333%; }
  .offset-fhd-5 {
    margin-left: 41.66667%; }
  .offset-fhd-6 {
    margin-left: 50%; }
  .offset-fhd-7 {
    margin-left: 58.33333%; }
  .offset-fhd-8 {
    margin-left: 66.66667%; }
  .offset-fhd-9 {
    margin-left: 75%; }
  .offset-fhd-10 {
    margin-left: 83.33333%; }
  .offset-fhd-11 {
    margin-left: 91.66667%; } }

@media (min-width: 2540px) {
  .col-qhd {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-qhd-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-qhd-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-qhd-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-qhd-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-qhd-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-qhd-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-qhd-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-qhd-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-qhd-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-qhd-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-qhd-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-qhd-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-qhd-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-qhd-first {
    order: -1; }
  .order-qhd-last {
    order: 13; }
  .order-qhd-0 {
    order: 0; }
  .order-qhd-1 {
    order: 1; }
  .order-qhd-2 {
    order: 2; }
  .order-qhd-3 {
    order: 3; }
  .order-qhd-4 {
    order: 4; }
  .order-qhd-5 {
    order: 5; }
  .order-qhd-6 {
    order: 6; }
  .order-qhd-7 {
    order: 7; }
  .order-qhd-8 {
    order: 8; }
  .order-qhd-9 {
    order: 9; }
  .order-qhd-10 {
    order: 10; }
  .order-qhd-11 {
    order: 11; }
  .order-qhd-12 {
    order: 12; }
  .offset-qhd-0 {
    margin-left: 0; }
  .offset-qhd-1 {
    margin-left: 8.33333%; }
  .offset-qhd-2 {
    margin-left: 16.66667%; }
  .offset-qhd-3 {
    margin-left: 25%; }
  .offset-qhd-4 {
    margin-left: 33.33333%; }
  .offset-qhd-5 {
    margin-left: 41.66667%; }
  .offset-qhd-6 {
    margin-left: 50%; }
  .offset-qhd-7 {
    margin-left: 58.33333%; }
  .offset-qhd-8 {
    margin-left: 66.66667%; }
  .offset-qhd-9 {
    margin-left: 75%; }
  .offset-qhd-10 {
    margin-left: 83.33333%; }
  .offset-qhd-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1900px) {
  .d-fhd-none {
    display: none !important; }
  .d-fhd-inline {
    display: inline !important; }
  .d-fhd-inline-block {
    display: inline-block !important; }
  .d-fhd-block {
    display: block !important; }
  .d-fhd-table {
    display: table !important; }
  .d-fhd-table-row {
    display: table-row !important; }
  .d-fhd-table-cell {
    display: table-cell !important; }
  .d-fhd-flex {
    display: flex !important; }
  .d-fhd-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 2540px) {
  .d-qhd-none {
    display: none !important; }
  .d-qhd-inline {
    display: inline !important; }
  .d-qhd-inline-block {
    display: inline-block !important; }
  .d-qhd-block {
    display: block !important; }
  .d-qhd-table {
    display: table !important; }
  .d-qhd-table-row {
    display: table-row !important; }
  .d-qhd-table-cell {
    display: table-cell !important; }
  .d-qhd-flex {
    display: flex !important; }
  .d-qhd-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1900px) {
  .flex-fhd-row {
    flex-direction: row !important; }
  .flex-fhd-column {
    flex-direction: column !important; }
  .flex-fhd-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-fhd-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-fhd-wrap {
    flex-wrap: wrap !important; }
  .flex-fhd-nowrap {
    flex-wrap: nowrap !important; }
  .flex-fhd-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-fhd-fill {
    flex: 1 1 auto !important; }
  .flex-fhd-grow-0 {
    flex-grow: 0 !important; }
  .flex-fhd-grow-1 {
    flex-grow: 1 !important; }
  .flex-fhd-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-fhd-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-fhd-start {
    justify-content: flex-start !important; }
  .justify-content-fhd-end {
    justify-content: flex-end !important; }
  .justify-content-fhd-center {
    justify-content: center !important; }
  .justify-content-fhd-between {
    justify-content: space-between !important; }
  .justify-content-fhd-around {
    justify-content: space-around !important; }
  .align-items-fhd-start {
    align-items: flex-start !important; }
  .align-items-fhd-end {
    align-items: flex-end !important; }
  .align-items-fhd-center {
    align-items: center !important; }
  .align-items-fhd-baseline {
    align-items: baseline !important; }
  .align-items-fhd-stretch {
    align-items: stretch !important; }
  .align-content-fhd-start {
    align-content: flex-start !important; }
  .align-content-fhd-end {
    align-content: flex-end !important; }
  .align-content-fhd-center {
    align-content: center !important; }
  .align-content-fhd-between {
    align-content: space-between !important; }
  .align-content-fhd-around {
    align-content: space-around !important; }
  .align-content-fhd-stretch {
    align-content: stretch !important; }
  .align-self-fhd-auto {
    align-self: auto !important; }
  .align-self-fhd-start {
    align-self: flex-start !important; }
  .align-self-fhd-end {
    align-self: flex-end !important; }
  .align-self-fhd-center {
    align-self: center !important; }
  .align-self-fhd-baseline {
    align-self: baseline !important; }
  .align-self-fhd-stretch {
    align-self: stretch !important; } }

@media (min-width: 2540px) {
  .flex-qhd-row {
    flex-direction: row !important; }
  .flex-qhd-column {
    flex-direction: column !important; }
  .flex-qhd-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-qhd-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-qhd-wrap {
    flex-wrap: wrap !important; }
  .flex-qhd-nowrap {
    flex-wrap: nowrap !important; }
  .flex-qhd-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-qhd-fill {
    flex: 1 1 auto !important; }
  .flex-qhd-grow-0 {
    flex-grow: 0 !important; }
  .flex-qhd-grow-1 {
    flex-grow: 1 !important; }
  .flex-qhd-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-qhd-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-qhd-start {
    justify-content: flex-start !important; }
  .justify-content-qhd-end {
    justify-content: flex-end !important; }
  .justify-content-qhd-center {
    justify-content: center !important; }
  .justify-content-qhd-between {
    justify-content: space-between !important; }
  .justify-content-qhd-around {
    justify-content: space-around !important; }
  .align-items-qhd-start {
    align-items: flex-start !important; }
  .align-items-qhd-end {
    align-items: flex-end !important; }
  .align-items-qhd-center {
    align-items: center !important; }
  .align-items-qhd-baseline {
    align-items: baseline !important; }
  .align-items-qhd-stretch {
    align-items: stretch !important; }
  .align-content-qhd-start {
    align-content: flex-start !important; }
  .align-content-qhd-end {
    align-content: flex-end !important; }
  .align-content-qhd-center {
    align-content: center !important; }
  .align-content-qhd-between {
    align-content: space-between !important; }
  .align-content-qhd-around {
    align-content: space-around !important; }
  .align-content-qhd-stretch {
    align-content: stretch !important; }
  .align-self-qhd-auto {
    align-self: auto !important; }
  .align-self-qhd-start {
    align-self: flex-start !important; }
  .align-self-qhd-end {
    align-self: flex-end !important; }
  .align-self-qhd-center {
    align-self: center !important; }
  .align-self-qhd-baseline {
    align-self: baseline !important; }
  .align-self-qhd-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 2rem !important; }

.mt-5,
.my-5 {
  margin-top: 2rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 2rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2rem !important; }

.m-6 {
  margin: 3rem !important; }

.mt-6,
.my-6 {
  margin-top: 3rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3rem !important; }

.m-7 {
  margin: 4rem !important; }

.mt-7,
.my-7 {
  margin-top: 4rem !important; }

.mr-7,
.mx-7 {
  margin-right: 4rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 4rem !important; }

.ml-7,
.mx-7 {
  margin-left: 4rem !important; }

.m-8 {
  margin: 5rem !important; }

.mt-8,
.my-8 {
  margin-top: 5rem !important; }

.mr-8,
.mx-8 {
  margin-right: 5rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 5rem !important; }

.ml-8,
.mx-8 {
  margin-left: 5rem !important; }

.m-9 {
  margin: 6rem !important; }

.mt-9,
.my-9 {
  margin-top: 6rem !important; }

.mr-9,
.mx-9 {
  margin-right: 6rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 6rem !important; }

.ml-9,
.mx-9 {
  margin-left: 6rem !important; }

.m-10 {
  margin: 7rem !important; }

.mt-10,
.my-10 {
  margin-top: 7rem !important; }

.mr-10,
.mx-10 {
  margin-right: 7rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 7rem !important; }

.ml-10,
.mx-10 {
  margin-left: 7rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 2rem !important; }

.pt-5,
.py-5 {
  padding-top: 2rem !important; }

.pr-5,
.px-5 {
  padding-right: 2rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 2rem !important; }

.pl-5,
.px-5 {
  padding-left: 2rem !important; }

.p-6 {
  padding: 3rem !important; }

.pt-6,
.py-6 {
  padding-top: 3rem !important; }

.pr-6,
.px-6 {
  padding-right: 3rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3rem !important; }

.pl-6,
.px-6 {
  padding-left: 3rem !important; }

.p-7 {
  padding: 4rem !important; }

.pt-7,
.py-7 {
  padding-top: 4rem !important; }

.pr-7,
.px-7 {
  padding-right: 4rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 4rem !important; }

.pl-7,
.px-7 {
  padding-left: 4rem !important; }

.p-8 {
  padding: 5rem !important; }

.pt-8,
.py-8 {
  padding-top: 5rem !important; }

.pr-8,
.px-8 {
  padding-right: 5rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 5rem !important; }

.pl-8,
.px-8 {
  padding-left: 5rem !important; }

.p-9 {
  padding: 6rem !important; }

.pt-9,
.py-9 {
  padding-top: 6rem !important; }

.pr-9,
.px-9 {
  padding-right: 6rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 6rem !important; }

.pl-9,
.px-9 {
  padding-left: 6rem !important; }

.p-10 {
  padding: 7rem !important; }

.pt-10,
.py-10 {
  padding-top: 7rem !important; }

.pr-10,
.px-10 {
  padding-right: 7rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 7rem !important; }

.pl-10,
.px-10 {
  padding-left: 7rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.25rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.25rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.25rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.25rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.25rem !important; }

.m-n3 {
  margin: -0.25rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -0.25rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -0.25rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -0.25rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -0.25rem !important; }

.m-n4 {
  margin: -0.25rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -0.25rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -0.25rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -0.25rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -0.25rem !important; }

.m-n5 {
  margin: -0.25rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -0.25rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -0.25rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -0.25rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -0.25rem !important; }

.m-n6 {
  margin: -0.25rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -0.25rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -0.25rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -0.25rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -0.25rem !important; }

.m-n7 {
  margin: -0.25rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -0.25rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -0.25rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -0.25rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -0.25rem !important; }

.m-n8 {
  margin: -0.25rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -0.25rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -0.25rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -0.25rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -0.25rem !important; }

.m-n9 {
  margin: -0.25rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -0.25rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -0.25rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -0.25rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -0.25rem !important; }

.m-n10 {
  margin: -0.25rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -0.25rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -0.25rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -0.25rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -0.25rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 2rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important; }
  .m-sm-6 {
    margin: 3rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3rem !important; }
  .m-sm-7 {
    margin: 4rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 4rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 4rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 4rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 4rem !important; }
  .m-sm-8 {
    margin: 5rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 5rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 5rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 5rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 5rem !important; }
  .m-sm-9 {
    margin: 6rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 6rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 6rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 6rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 6rem !important; }
  .m-sm-10 {
    margin: 7rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 7rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 7rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 7rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 7rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 2rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important; }
  .p-sm-6 {
    padding: 3rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3rem !important; }
  .p-sm-7 {
    padding: 4rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 4rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 4rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 4rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 4rem !important; }
  .p-sm-8 {
    padding: 5rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 5rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 5rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 5rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 5rem !important; }
  .p-sm-9 {
    padding: 6rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 6rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 6rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 6rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 6rem !important; }
  .p-sm-10 {
    padding: 7rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 7rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 7rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 7rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 7rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.25rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.25rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.25rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.25rem !important; }
  .m-sm-n3 {
    margin: -0.25rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.25rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.25rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.25rem !important; }
  .m-sm-n4 {
    margin: -0.25rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -0.25rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -0.25rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -0.25rem !important; }
  .m-sm-n5 {
    margin: -0.25rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -0.25rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -0.25rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -0.25rem !important; }
  .m-sm-n6 {
    margin: -0.25rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -0.25rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -0.25rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -0.25rem !important; }
  .m-sm-n7 {
    margin: -0.25rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -0.25rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -0.25rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -0.25rem !important; }
  .m-sm-n8 {
    margin: -0.25rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -0.25rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -0.25rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -0.25rem !important; }
  .m-sm-n9 {
    margin: -0.25rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -0.25rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -0.25rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -0.25rem !important; }
  .m-sm-n10 {
    margin: -0.25rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -0.25rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -0.25rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -0.25rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 2rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important; }
  .m-md-6 {
    margin: 3rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3rem !important; }
  .m-md-7 {
    margin: 4rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 4rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 4rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 4rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 4rem !important; }
  .m-md-8 {
    margin: 5rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 5rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 5rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 5rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 5rem !important; }
  .m-md-9 {
    margin: 6rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 6rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 6rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 6rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 6rem !important; }
  .m-md-10 {
    margin: 7rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 7rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 7rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 7rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 7rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 2rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important; }
  .p-md-6 {
    padding: 3rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3rem !important; }
  .p-md-7 {
    padding: 4rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 4rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 4rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 4rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 4rem !important; }
  .p-md-8 {
    padding: 5rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 5rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 5rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 5rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 5rem !important; }
  .p-md-9 {
    padding: 6rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 6rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 6rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 6rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 6rem !important; }
  .p-md-10 {
    padding: 7rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 7rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 7rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 7rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 7rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.25rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.25rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.25rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.25rem !important; }
  .m-md-n3 {
    margin: -0.25rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.25rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.25rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.25rem !important; }
  .m-md-n4 {
    margin: -0.25rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -0.25rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -0.25rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -0.25rem !important; }
  .m-md-n5 {
    margin: -0.25rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -0.25rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -0.25rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -0.25rem !important; }
  .m-md-n6 {
    margin: -0.25rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -0.25rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -0.25rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -0.25rem !important; }
  .m-md-n7 {
    margin: -0.25rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -0.25rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -0.25rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -0.25rem !important; }
  .m-md-n8 {
    margin: -0.25rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -0.25rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -0.25rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -0.25rem !important; }
  .m-md-n9 {
    margin: -0.25rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -0.25rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -0.25rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -0.25rem !important; }
  .m-md-n10 {
    margin: -0.25rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -0.25rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -0.25rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -0.25rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 2rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important; }
  .m-lg-6 {
    margin: 3rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3rem !important; }
  .m-lg-7 {
    margin: 4rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 4rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 4rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 4rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 4rem !important; }
  .m-lg-8 {
    margin: 5rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 5rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 5rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 5rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 5rem !important; }
  .m-lg-9 {
    margin: 6rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 6rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 6rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 6rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 6rem !important; }
  .m-lg-10 {
    margin: 7rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 7rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 7rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 7rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 7rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 2rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important; }
  .p-lg-6 {
    padding: 3rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3rem !important; }
  .p-lg-7 {
    padding: 4rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 4rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 4rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 4rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 4rem !important; }
  .p-lg-8 {
    padding: 5rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 5rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 5rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 5rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 5rem !important; }
  .p-lg-9 {
    padding: 6rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 6rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 6rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 6rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 6rem !important; }
  .p-lg-10 {
    padding: 7rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 7rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 7rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 7rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 7rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.25rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.25rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.25rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.25rem !important; }
  .m-lg-n3 {
    margin: -0.25rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.25rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.25rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.25rem !important; }
  .m-lg-n4 {
    margin: -0.25rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -0.25rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -0.25rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -0.25rem !important; }
  .m-lg-n5 {
    margin: -0.25rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -0.25rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -0.25rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -0.25rem !important; }
  .m-lg-n6 {
    margin: -0.25rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -0.25rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -0.25rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -0.25rem !important; }
  .m-lg-n7 {
    margin: -0.25rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -0.25rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -0.25rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -0.25rem !important; }
  .m-lg-n8 {
    margin: -0.25rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -0.25rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -0.25rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -0.25rem !important; }
  .m-lg-n9 {
    margin: -0.25rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -0.25rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -0.25rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -0.25rem !important; }
  .m-lg-n10 {
    margin: -0.25rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -0.25rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -0.25rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -0.25rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 2rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important; }
  .m-xl-6 {
    margin: 3rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3rem !important; }
  .m-xl-7 {
    margin: 4rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 4rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 4rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 4rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 4rem !important; }
  .m-xl-8 {
    margin: 5rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 5rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 5rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 5rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 5rem !important; }
  .m-xl-9 {
    margin: 6rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 6rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 6rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 6rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 6rem !important; }
  .m-xl-10 {
    margin: 7rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 7rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 7rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 7rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 7rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 2rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important; }
  .p-xl-6 {
    padding: 3rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3rem !important; }
  .p-xl-7 {
    padding: 4rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 4rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 4rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 4rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 4rem !important; }
  .p-xl-8 {
    padding: 5rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 5rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 5rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 5rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 5rem !important; }
  .p-xl-9 {
    padding: 6rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 6rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 6rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 6rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 6rem !important; }
  .p-xl-10 {
    padding: 7rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 7rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 7rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 7rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 7rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.25rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.25rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.25rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.25rem !important; }
  .m-xl-n3 {
    margin: -0.25rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.25rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.25rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.25rem !important; }
  .m-xl-n4 {
    margin: -0.25rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -0.25rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -0.25rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -0.25rem !important; }
  .m-xl-n5 {
    margin: -0.25rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -0.25rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -0.25rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -0.25rem !important; }
  .m-xl-n6 {
    margin: -0.25rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -0.25rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -0.25rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -0.25rem !important; }
  .m-xl-n7 {
    margin: -0.25rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -0.25rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -0.25rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -0.25rem !important; }
  .m-xl-n8 {
    margin: -0.25rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -0.25rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -0.25rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -0.25rem !important; }
  .m-xl-n9 {
    margin: -0.25rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -0.25rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -0.25rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -0.25rem !important; }
  .m-xl-n10 {
    margin: -0.25rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -0.25rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -0.25rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -0.25rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1900px) {
  .m-fhd-0 {
    margin: 0 !important; }
  .mt-fhd-0,
  .my-fhd-0 {
    margin-top: 0 !important; }
  .mr-fhd-0,
  .mx-fhd-0 {
    margin-right: 0 !important; }
  .mb-fhd-0,
  .my-fhd-0 {
    margin-bottom: 0 !important; }
  .ml-fhd-0,
  .mx-fhd-0 {
    margin-left: 0 !important; }
  .m-fhd-1 {
    margin: 0.25rem !important; }
  .mt-fhd-1,
  .my-fhd-1 {
    margin-top: 0.25rem !important; }
  .mr-fhd-1,
  .mx-fhd-1 {
    margin-right: 0.25rem !important; }
  .mb-fhd-1,
  .my-fhd-1 {
    margin-bottom: 0.25rem !important; }
  .ml-fhd-1,
  .mx-fhd-1 {
    margin-left: 0.25rem !important; }
  .m-fhd-2 {
    margin: 0.5rem !important; }
  .mt-fhd-2,
  .my-fhd-2 {
    margin-top: 0.5rem !important; }
  .mr-fhd-2,
  .mx-fhd-2 {
    margin-right: 0.5rem !important; }
  .mb-fhd-2,
  .my-fhd-2 {
    margin-bottom: 0.5rem !important; }
  .ml-fhd-2,
  .mx-fhd-2 {
    margin-left: 0.5rem !important; }
  .m-fhd-3 {
    margin: 1rem !important; }
  .mt-fhd-3,
  .my-fhd-3 {
    margin-top: 1rem !important; }
  .mr-fhd-3,
  .mx-fhd-3 {
    margin-right: 1rem !important; }
  .mb-fhd-3,
  .my-fhd-3 {
    margin-bottom: 1rem !important; }
  .ml-fhd-3,
  .mx-fhd-3 {
    margin-left: 1rem !important; }
  .m-fhd-4 {
    margin: 1.5rem !important; }
  .mt-fhd-4,
  .my-fhd-4 {
    margin-top: 1.5rem !important; }
  .mr-fhd-4,
  .mx-fhd-4 {
    margin-right: 1.5rem !important; }
  .mb-fhd-4,
  .my-fhd-4 {
    margin-bottom: 1.5rem !important; }
  .ml-fhd-4,
  .mx-fhd-4 {
    margin-left: 1.5rem !important; }
  .m-fhd-5 {
    margin: 2rem !important; }
  .mt-fhd-5,
  .my-fhd-5 {
    margin-top: 2rem !important; }
  .mr-fhd-5,
  .mx-fhd-5 {
    margin-right: 2rem !important; }
  .mb-fhd-5,
  .my-fhd-5 {
    margin-bottom: 2rem !important; }
  .ml-fhd-5,
  .mx-fhd-5 {
    margin-left: 2rem !important; }
  .m-fhd-6 {
    margin: 3rem !important; }
  .mt-fhd-6,
  .my-fhd-6 {
    margin-top: 3rem !important; }
  .mr-fhd-6,
  .mx-fhd-6 {
    margin-right: 3rem !important; }
  .mb-fhd-6,
  .my-fhd-6 {
    margin-bottom: 3rem !important; }
  .ml-fhd-6,
  .mx-fhd-6 {
    margin-left: 3rem !important; }
  .m-fhd-7 {
    margin: 4rem !important; }
  .mt-fhd-7,
  .my-fhd-7 {
    margin-top: 4rem !important; }
  .mr-fhd-7,
  .mx-fhd-7 {
    margin-right: 4rem !important; }
  .mb-fhd-7,
  .my-fhd-7 {
    margin-bottom: 4rem !important; }
  .ml-fhd-7,
  .mx-fhd-7 {
    margin-left: 4rem !important; }
  .m-fhd-8 {
    margin: 5rem !important; }
  .mt-fhd-8,
  .my-fhd-8 {
    margin-top: 5rem !important; }
  .mr-fhd-8,
  .mx-fhd-8 {
    margin-right: 5rem !important; }
  .mb-fhd-8,
  .my-fhd-8 {
    margin-bottom: 5rem !important; }
  .ml-fhd-8,
  .mx-fhd-8 {
    margin-left: 5rem !important; }
  .m-fhd-9 {
    margin: 6rem !important; }
  .mt-fhd-9,
  .my-fhd-9 {
    margin-top: 6rem !important; }
  .mr-fhd-9,
  .mx-fhd-9 {
    margin-right: 6rem !important; }
  .mb-fhd-9,
  .my-fhd-9 {
    margin-bottom: 6rem !important; }
  .ml-fhd-9,
  .mx-fhd-9 {
    margin-left: 6rem !important; }
  .m-fhd-10 {
    margin: 7rem !important; }
  .mt-fhd-10,
  .my-fhd-10 {
    margin-top: 7rem !important; }
  .mr-fhd-10,
  .mx-fhd-10 {
    margin-right: 7rem !important; }
  .mb-fhd-10,
  .my-fhd-10 {
    margin-bottom: 7rem !important; }
  .ml-fhd-10,
  .mx-fhd-10 {
    margin-left: 7rem !important; }
  .p-fhd-0 {
    padding: 0 !important; }
  .pt-fhd-0,
  .py-fhd-0 {
    padding-top: 0 !important; }
  .pr-fhd-0,
  .px-fhd-0 {
    padding-right: 0 !important; }
  .pb-fhd-0,
  .py-fhd-0 {
    padding-bottom: 0 !important; }
  .pl-fhd-0,
  .px-fhd-0 {
    padding-left: 0 !important; }
  .p-fhd-1 {
    padding: 0.25rem !important; }
  .pt-fhd-1,
  .py-fhd-1 {
    padding-top: 0.25rem !important; }
  .pr-fhd-1,
  .px-fhd-1 {
    padding-right: 0.25rem !important; }
  .pb-fhd-1,
  .py-fhd-1 {
    padding-bottom: 0.25rem !important; }
  .pl-fhd-1,
  .px-fhd-1 {
    padding-left: 0.25rem !important; }
  .p-fhd-2 {
    padding: 0.5rem !important; }
  .pt-fhd-2,
  .py-fhd-2 {
    padding-top: 0.5rem !important; }
  .pr-fhd-2,
  .px-fhd-2 {
    padding-right: 0.5rem !important; }
  .pb-fhd-2,
  .py-fhd-2 {
    padding-bottom: 0.5rem !important; }
  .pl-fhd-2,
  .px-fhd-2 {
    padding-left: 0.5rem !important; }
  .p-fhd-3 {
    padding: 1rem !important; }
  .pt-fhd-3,
  .py-fhd-3 {
    padding-top: 1rem !important; }
  .pr-fhd-3,
  .px-fhd-3 {
    padding-right: 1rem !important; }
  .pb-fhd-3,
  .py-fhd-3 {
    padding-bottom: 1rem !important; }
  .pl-fhd-3,
  .px-fhd-3 {
    padding-left: 1rem !important; }
  .p-fhd-4 {
    padding: 1.5rem !important; }
  .pt-fhd-4,
  .py-fhd-4 {
    padding-top: 1.5rem !important; }
  .pr-fhd-4,
  .px-fhd-4 {
    padding-right: 1.5rem !important; }
  .pb-fhd-4,
  .py-fhd-4 {
    padding-bottom: 1.5rem !important; }
  .pl-fhd-4,
  .px-fhd-4 {
    padding-left: 1.5rem !important; }
  .p-fhd-5 {
    padding: 2rem !important; }
  .pt-fhd-5,
  .py-fhd-5 {
    padding-top: 2rem !important; }
  .pr-fhd-5,
  .px-fhd-5 {
    padding-right: 2rem !important; }
  .pb-fhd-5,
  .py-fhd-5 {
    padding-bottom: 2rem !important; }
  .pl-fhd-5,
  .px-fhd-5 {
    padding-left: 2rem !important; }
  .p-fhd-6 {
    padding: 3rem !important; }
  .pt-fhd-6,
  .py-fhd-6 {
    padding-top: 3rem !important; }
  .pr-fhd-6,
  .px-fhd-6 {
    padding-right: 3rem !important; }
  .pb-fhd-6,
  .py-fhd-6 {
    padding-bottom: 3rem !important; }
  .pl-fhd-6,
  .px-fhd-6 {
    padding-left: 3rem !important; }
  .p-fhd-7 {
    padding: 4rem !important; }
  .pt-fhd-7,
  .py-fhd-7 {
    padding-top: 4rem !important; }
  .pr-fhd-7,
  .px-fhd-7 {
    padding-right: 4rem !important; }
  .pb-fhd-7,
  .py-fhd-7 {
    padding-bottom: 4rem !important; }
  .pl-fhd-7,
  .px-fhd-7 {
    padding-left: 4rem !important; }
  .p-fhd-8 {
    padding: 5rem !important; }
  .pt-fhd-8,
  .py-fhd-8 {
    padding-top: 5rem !important; }
  .pr-fhd-8,
  .px-fhd-8 {
    padding-right: 5rem !important; }
  .pb-fhd-8,
  .py-fhd-8 {
    padding-bottom: 5rem !important; }
  .pl-fhd-8,
  .px-fhd-8 {
    padding-left: 5rem !important; }
  .p-fhd-9 {
    padding: 6rem !important; }
  .pt-fhd-9,
  .py-fhd-9 {
    padding-top: 6rem !important; }
  .pr-fhd-9,
  .px-fhd-9 {
    padding-right: 6rem !important; }
  .pb-fhd-9,
  .py-fhd-9 {
    padding-bottom: 6rem !important; }
  .pl-fhd-9,
  .px-fhd-9 {
    padding-left: 6rem !important; }
  .p-fhd-10 {
    padding: 7rem !important; }
  .pt-fhd-10,
  .py-fhd-10 {
    padding-top: 7rem !important; }
  .pr-fhd-10,
  .px-fhd-10 {
    padding-right: 7rem !important; }
  .pb-fhd-10,
  .py-fhd-10 {
    padding-bottom: 7rem !important; }
  .pl-fhd-10,
  .px-fhd-10 {
    padding-left: 7rem !important; }
  .m-fhd-n1 {
    margin: -0.25rem !important; }
  .mt-fhd-n1,
  .my-fhd-n1 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n1,
  .mx-fhd-n1 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n1,
  .my-fhd-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n1,
  .mx-fhd-n1 {
    margin-left: -0.25rem !important; }
  .m-fhd-n2 {
    margin: -0.25rem !important; }
  .mt-fhd-n2,
  .my-fhd-n2 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n2,
  .mx-fhd-n2 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n2,
  .my-fhd-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n2,
  .mx-fhd-n2 {
    margin-left: -0.25rem !important; }
  .m-fhd-n3 {
    margin: -0.25rem !important; }
  .mt-fhd-n3,
  .my-fhd-n3 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n3,
  .mx-fhd-n3 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n3,
  .my-fhd-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n3,
  .mx-fhd-n3 {
    margin-left: -0.25rem !important; }
  .m-fhd-n4 {
    margin: -0.25rem !important; }
  .mt-fhd-n4,
  .my-fhd-n4 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n4,
  .mx-fhd-n4 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n4,
  .my-fhd-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n4,
  .mx-fhd-n4 {
    margin-left: -0.25rem !important; }
  .m-fhd-n5 {
    margin: -0.25rem !important; }
  .mt-fhd-n5,
  .my-fhd-n5 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n5,
  .mx-fhd-n5 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n5,
  .my-fhd-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n5,
  .mx-fhd-n5 {
    margin-left: -0.25rem !important; }
  .m-fhd-n6 {
    margin: -0.25rem !important; }
  .mt-fhd-n6,
  .my-fhd-n6 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n6,
  .mx-fhd-n6 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n6,
  .my-fhd-n6 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n6,
  .mx-fhd-n6 {
    margin-left: -0.25rem !important; }
  .m-fhd-n7 {
    margin: -0.25rem !important; }
  .mt-fhd-n7,
  .my-fhd-n7 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n7,
  .mx-fhd-n7 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n7,
  .my-fhd-n7 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n7,
  .mx-fhd-n7 {
    margin-left: -0.25rem !important; }
  .m-fhd-n8 {
    margin: -0.25rem !important; }
  .mt-fhd-n8,
  .my-fhd-n8 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n8,
  .mx-fhd-n8 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n8,
  .my-fhd-n8 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n8,
  .mx-fhd-n8 {
    margin-left: -0.25rem !important; }
  .m-fhd-n9 {
    margin: -0.25rem !important; }
  .mt-fhd-n9,
  .my-fhd-n9 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n9,
  .mx-fhd-n9 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n9,
  .my-fhd-n9 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n9,
  .mx-fhd-n9 {
    margin-left: -0.25rem !important; }
  .m-fhd-n10 {
    margin: -0.25rem !important; }
  .mt-fhd-n10,
  .my-fhd-n10 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n10,
  .mx-fhd-n10 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n10,
  .my-fhd-n10 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n10,
  .mx-fhd-n10 {
    margin-left: -0.25rem !important; }
  .m-fhd-auto {
    margin: auto !important; }
  .mt-fhd-auto,
  .my-fhd-auto {
    margin-top: auto !important; }
  .mr-fhd-auto,
  .mx-fhd-auto {
    margin-right: auto !important; }
  .mb-fhd-auto,
  .my-fhd-auto {
    margin-bottom: auto !important; }
  .ml-fhd-auto,
  .mx-fhd-auto {
    margin-left: auto !important; } }

@media (min-width: 2540px) {
  .m-qhd-0 {
    margin: 0 !important; }
  .mt-qhd-0,
  .my-qhd-0 {
    margin-top: 0 !important; }
  .mr-qhd-0,
  .mx-qhd-0 {
    margin-right: 0 !important; }
  .mb-qhd-0,
  .my-qhd-0 {
    margin-bottom: 0 !important; }
  .ml-qhd-0,
  .mx-qhd-0 {
    margin-left: 0 !important; }
  .m-qhd-1 {
    margin: 0.25rem !important; }
  .mt-qhd-1,
  .my-qhd-1 {
    margin-top: 0.25rem !important; }
  .mr-qhd-1,
  .mx-qhd-1 {
    margin-right: 0.25rem !important; }
  .mb-qhd-1,
  .my-qhd-1 {
    margin-bottom: 0.25rem !important; }
  .ml-qhd-1,
  .mx-qhd-1 {
    margin-left: 0.25rem !important; }
  .m-qhd-2 {
    margin: 0.5rem !important; }
  .mt-qhd-2,
  .my-qhd-2 {
    margin-top: 0.5rem !important; }
  .mr-qhd-2,
  .mx-qhd-2 {
    margin-right: 0.5rem !important; }
  .mb-qhd-2,
  .my-qhd-2 {
    margin-bottom: 0.5rem !important; }
  .ml-qhd-2,
  .mx-qhd-2 {
    margin-left: 0.5rem !important; }
  .m-qhd-3 {
    margin: 1rem !important; }
  .mt-qhd-3,
  .my-qhd-3 {
    margin-top: 1rem !important; }
  .mr-qhd-3,
  .mx-qhd-3 {
    margin-right: 1rem !important; }
  .mb-qhd-3,
  .my-qhd-3 {
    margin-bottom: 1rem !important; }
  .ml-qhd-3,
  .mx-qhd-3 {
    margin-left: 1rem !important; }
  .m-qhd-4 {
    margin: 1.5rem !important; }
  .mt-qhd-4,
  .my-qhd-4 {
    margin-top: 1.5rem !important; }
  .mr-qhd-4,
  .mx-qhd-4 {
    margin-right: 1.5rem !important; }
  .mb-qhd-4,
  .my-qhd-4 {
    margin-bottom: 1.5rem !important; }
  .ml-qhd-4,
  .mx-qhd-4 {
    margin-left: 1.5rem !important; }
  .m-qhd-5 {
    margin: 2rem !important; }
  .mt-qhd-5,
  .my-qhd-5 {
    margin-top: 2rem !important; }
  .mr-qhd-5,
  .mx-qhd-5 {
    margin-right: 2rem !important; }
  .mb-qhd-5,
  .my-qhd-5 {
    margin-bottom: 2rem !important; }
  .ml-qhd-5,
  .mx-qhd-5 {
    margin-left: 2rem !important; }
  .m-qhd-6 {
    margin: 3rem !important; }
  .mt-qhd-6,
  .my-qhd-6 {
    margin-top: 3rem !important; }
  .mr-qhd-6,
  .mx-qhd-6 {
    margin-right: 3rem !important; }
  .mb-qhd-6,
  .my-qhd-6 {
    margin-bottom: 3rem !important; }
  .ml-qhd-6,
  .mx-qhd-6 {
    margin-left: 3rem !important; }
  .m-qhd-7 {
    margin: 4rem !important; }
  .mt-qhd-7,
  .my-qhd-7 {
    margin-top: 4rem !important; }
  .mr-qhd-7,
  .mx-qhd-7 {
    margin-right: 4rem !important; }
  .mb-qhd-7,
  .my-qhd-7 {
    margin-bottom: 4rem !important; }
  .ml-qhd-7,
  .mx-qhd-7 {
    margin-left: 4rem !important; }
  .m-qhd-8 {
    margin: 5rem !important; }
  .mt-qhd-8,
  .my-qhd-8 {
    margin-top: 5rem !important; }
  .mr-qhd-8,
  .mx-qhd-8 {
    margin-right: 5rem !important; }
  .mb-qhd-8,
  .my-qhd-8 {
    margin-bottom: 5rem !important; }
  .ml-qhd-8,
  .mx-qhd-8 {
    margin-left: 5rem !important; }
  .m-qhd-9 {
    margin: 6rem !important; }
  .mt-qhd-9,
  .my-qhd-9 {
    margin-top: 6rem !important; }
  .mr-qhd-9,
  .mx-qhd-9 {
    margin-right: 6rem !important; }
  .mb-qhd-9,
  .my-qhd-9 {
    margin-bottom: 6rem !important; }
  .ml-qhd-9,
  .mx-qhd-9 {
    margin-left: 6rem !important; }
  .m-qhd-10 {
    margin: 7rem !important; }
  .mt-qhd-10,
  .my-qhd-10 {
    margin-top: 7rem !important; }
  .mr-qhd-10,
  .mx-qhd-10 {
    margin-right: 7rem !important; }
  .mb-qhd-10,
  .my-qhd-10 {
    margin-bottom: 7rem !important; }
  .ml-qhd-10,
  .mx-qhd-10 {
    margin-left: 7rem !important; }
  .p-qhd-0 {
    padding: 0 !important; }
  .pt-qhd-0,
  .py-qhd-0 {
    padding-top: 0 !important; }
  .pr-qhd-0,
  .px-qhd-0 {
    padding-right: 0 !important; }
  .pb-qhd-0,
  .py-qhd-0 {
    padding-bottom: 0 !important; }
  .pl-qhd-0,
  .px-qhd-0 {
    padding-left: 0 !important; }
  .p-qhd-1 {
    padding: 0.25rem !important; }
  .pt-qhd-1,
  .py-qhd-1 {
    padding-top: 0.25rem !important; }
  .pr-qhd-1,
  .px-qhd-1 {
    padding-right: 0.25rem !important; }
  .pb-qhd-1,
  .py-qhd-1 {
    padding-bottom: 0.25rem !important; }
  .pl-qhd-1,
  .px-qhd-1 {
    padding-left: 0.25rem !important; }
  .p-qhd-2 {
    padding: 0.5rem !important; }
  .pt-qhd-2,
  .py-qhd-2 {
    padding-top: 0.5rem !important; }
  .pr-qhd-2,
  .px-qhd-2 {
    padding-right: 0.5rem !important; }
  .pb-qhd-2,
  .py-qhd-2 {
    padding-bottom: 0.5rem !important; }
  .pl-qhd-2,
  .px-qhd-2 {
    padding-left: 0.5rem !important; }
  .p-qhd-3 {
    padding: 1rem !important; }
  .pt-qhd-3,
  .py-qhd-3 {
    padding-top: 1rem !important; }
  .pr-qhd-3,
  .px-qhd-3 {
    padding-right: 1rem !important; }
  .pb-qhd-3,
  .py-qhd-3 {
    padding-bottom: 1rem !important; }
  .pl-qhd-3,
  .px-qhd-3 {
    padding-left: 1rem !important; }
  .p-qhd-4 {
    padding: 1.5rem !important; }
  .pt-qhd-4,
  .py-qhd-4 {
    padding-top: 1.5rem !important; }
  .pr-qhd-4,
  .px-qhd-4 {
    padding-right: 1.5rem !important; }
  .pb-qhd-4,
  .py-qhd-4 {
    padding-bottom: 1.5rem !important; }
  .pl-qhd-4,
  .px-qhd-4 {
    padding-left: 1.5rem !important; }
  .p-qhd-5 {
    padding: 2rem !important; }
  .pt-qhd-5,
  .py-qhd-5 {
    padding-top: 2rem !important; }
  .pr-qhd-5,
  .px-qhd-5 {
    padding-right: 2rem !important; }
  .pb-qhd-5,
  .py-qhd-5 {
    padding-bottom: 2rem !important; }
  .pl-qhd-5,
  .px-qhd-5 {
    padding-left: 2rem !important; }
  .p-qhd-6 {
    padding: 3rem !important; }
  .pt-qhd-6,
  .py-qhd-6 {
    padding-top: 3rem !important; }
  .pr-qhd-6,
  .px-qhd-6 {
    padding-right: 3rem !important; }
  .pb-qhd-6,
  .py-qhd-6 {
    padding-bottom: 3rem !important; }
  .pl-qhd-6,
  .px-qhd-6 {
    padding-left: 3rem !important; }
  .p-qhd-7 {
    padding: 4rem !important; }
  .pt-qhd-7,
  .py-qhd-7 {
    padding-top: 4rem !important; }
  .pr-qhd-7,
  .px-qhd-7 {
    padding-right: 4rem !important; }
  .pb-qhd-7,
  .py-qhd-7 {
    padding-bottom: 4rem !important; }
  .pl-qhd-7,
  .px-qhd-7 {
    padding-left: 4rem !important; }
  .p-qhd-8 {
    padding: 5rem !important; }
  .pt-qhd-8,
  .py-qhd-8 {
    padding-top: 5rem !important; }
  .pr-qhd-8,
  .px-qhd-8 {
    padding-right: 5rem !important; }
  .pb-qhd-8,
  .py-qhd-8 {
    padding-bottom: 5rem !important; }
  .pl-qhd-8,
  .px-qhd-8 {
    padding-left: 5rem !important; }
  .p-qhd-9 {
    padding: 6rem !important; }
  .pt-qhd-9,
  .py-qhd-9 {
    padding-top: 6rem !important; }
  .pr-qhd-9,
  .px-qhd-9 {
    padding-right: 6rem !important; }
  .pb-qhd-9,
  .py-qhd-9 {
    padding-bottom: 6rem !important; }
  .pl-qhd-9,
  .px-qhd-9 {
    padding-left: 6rem !important; }
  .p-qhd-10 {
    padding: 7rem !important; }
  .pt-qhd-10,
  .py-qhd-10 {
    padding-top: 7rem !important; }
  .pr-qhd-10,
  .px-qhd-10 {
    padding-right: 7rem !important; }
  .pb-qhd-10,
  .py-qhd-10 {
    padding-bottom: 7rem !important; }
  .pl-qhd-10,
  .px-qhd-10 {
    padding-left: 7rem !important; }
  .m-qhd-n1 {
    margin: -0.25rem !important; }
  .mt-qhd-n1,
  .my-qhd-n1 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n1,
  .mx-qhd-n1 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n1,
  .my-qhd-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n1,
  .mx-qhd-n1 {
    margin-left: -0.25rem !important; }
  .m-qhd-n2 {
    margin: -0.25rem !important; }
  .mt-qhd-n2,
  .my-qhd-n2 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n2,
  .mx-qhd-n2 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n2,
  .my-qhd-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n2,
  .mx-qhd-n2 {
    margin-left: -0.25rem !important; }
  .m-qhd-n3 {
    margin: -0.25rem !important; }
  .mt-qhd-n3,
  .my-qhd-n3 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n3,
  .mx-qhd-n3 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n3,
  .my-qhd-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n3,
  .mx-qhd-n3 {
    margin-left: -0.25rem !important; }
  .m-qhd-n4 {
    margin: -0.25rem !important; }
  .mt-qhd-n4,
  .my-qhd-n4 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n4,
  .mx-qhd-n4 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n4,
  .my-qhd-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n4,
  .mx-qhd-n4 {
    margin-left: -0.25rem !important; }
  .m-qhd-n5 {
    margin: -0.25rem !important; }
  .mt-qhd-n5,
  .my-qhd-n5 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n5,
  .mx-qhd-n5 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n5,
  .my-qhd-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n5,
  .mx-qhd-n5 {
    margin-left: -0.25rem !important; }
  .m-qhd-n6 {
    margin: -0.25rem !important; }
  .mt-qhd-n6,
  .my-qhd-n6 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n6,
  .mx-qhd-n6 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n6,
  .my-qhd-n6 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n6,
  .mx-qhd-n6 {
    margin-left: -0.25rem !important; }
  .m-qhd-n7 {
    margin: -0.25rem !important; }
  .mt-qhd-n7,
  .my-qhd-n7 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n7,
  .mx-qhd-n7 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n7,
  .my-qhd-n7 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n7,
  .mx-qhd-n7 {
    margin-left: -0.25rem !important; }
  .m-qhd-n8 {
    margin: -0.25rem !important; }
  .mt-qhd-n8,
  .my-qhd-n8 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n8,
  .mx-qhd-n8 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n8,
  .my-qhd-n8 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n8,
  .mx-qhd-n8 {
    margin-left: -0.25rem !important; }
  .m-qhd-n9 {
    margin: -0.25rem !important; }
  .mt-qhd-n9,
  .my-qhd-n9 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n9,
  .mx-qhd-n9 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n9,
  .my-qhd-n9 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n9,
  .mx-qhd-n9 {
    margin-left: -0.25rem !important; }
  .m-qhd-n10 {
    margin: -0.25rem !important; }
  .mt-qhd-n10,
  .my-qhd-n10 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n10,
  .mx-qhd-n10 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n10,
  .my-qhd-n10 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n10,
  .mx-qhd-n10 {
    margin-left: -0.25rem !important; }
  .m-qhd-auto {
    margin: auto !important; }
  .mt-qhd-auto,
  .my-qhd-auto {
    margin-top: auto !important; }
  .mr-qhd-auto,
  .mx-qhd-auto {
    margin-right: auto !important; }
  .mb-qhd-auto,
  .my-qhd-auto {
    margin-bottom: auto !important; }
  .ml-qhd-auto,
  .mx-qhd-auto {
    margin-left: auto !important; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 2rem;
  list-style: none;
  background-color: none;
  border-radius: 5px; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #a1a2af;
    content: ""; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #1f2041; }

.btn, #login .button.button-primary {
  display: inline-block;
  font-family: "Avenir LT W01_85 Heavy1475544", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  color: #212121;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, #login .button.button-primary {
      transition: none; } }
  .btn:hover, #login .button.button-primary:hover {
    color: #212121;
    text-decoration: none; }
  .btn:focus, #login .button.button-primary:focus, .btn.focus, #login .focus.button.button-primary {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 171, 108, 0.25); }
  .btn.disabled, #login .disabled.button.button-primary, .btn:disabled, #login .button.button-primary:disabled {
    opacity: 0.65; }

a.btn.disabled, #login a.disabled.button.button-primary,
fieldset:disabled a.btn,
fieldset:disabled #login a.button.button-primary,
#login fieldset:disabled a.button.button-primary {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #1f2041;
  border-color: #1f2041; }
  .btn-primary:hover {
    color: #fff;
    background-color: #131327;
    border-color: #0f0f1e; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 65, 94, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #1f2041;
    border-color: #1f2041; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0f0f1e;
    border-color: #0a0b16; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(65, 65, 94, 0.5); }

.btn-secondary, #login .button.button-primary {
  color: #fff;
  background-color: #2cab6c;
  border-color: #2cab6c; }
  .btn-secondary:hover, #login .button.button-primary:hover {
    color: #fff;
    background-color: #248d59;
    border-color: #228252; }
  .btn-secondary:focus, #login .button.button-primary:focus, .btn-secondary.focus, #login .focus.button.button-primary {
    box-shadow: 0 0 0 0.2rem rgba(76, 184, 130, 0.5); }
  .btn-secondary.disabled, #login .disabled.button.button-primary, .btn-secondary:disabled, #login .button.button-primary:disabled {
    color: #fff;
    background-color: #2cab6c;
    border-color: #2cab6c; }
  .btn-secondary:not(:disabled):not(.disabled):active, #login .button.button-primary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, #login .button.button-primary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle,
  #login .show > .dropdown-toggle.button.button-primary {
    color: #fff;
    background-color: #228252;
    border-color: #1f784c; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, #login .button.button-primary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, #login .button.button-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus,
    #login .show > .dropdown-toggle.button.button-primary:focus {
      box-shadow: 0 0 0 0.2rem rgba(76, 184, 130, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-light:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #212121;
  border-color: #212121; }
  .btn-dark:hover {
    color: #fff;
    background-color: #0e0e0e;
    border-color: #080808; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #212121;
    border-color: #212121; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #080808;
    border-color: #010101; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }

.btn-ternary {
  color: #212529;
  background-color: #f7fcfe;
  border-color: #f7fcfe; }
  .btn-ternary:hover {
    color: #212529;
    background-color: #d5effa;
    border-color: #caebf8; }
  .btn-ternary:focus, .btn-ternary.focus {
    box-shadow: 0 0 0 0.2rem rgba(215, 220, 222, 0.5); }
  .btn-ternary.disabled, .btn-ternary:disabled {
    color: #212529;
    background-color: #f7fcfe;
    border-color: #f7fcfe; }
  .btn-ternary:not(:disabled):not(.disabled):active, .btn-ternary:not(:disabled):not(.disabled).active,
  .show > .btn-ternary.dropdown-toggle {
    color: #212529;
    background-color: #caebf8;
    border-color: #bee7f7; }
    .btn-ternary:not(:disabled):not(.disabled):active:focus, .btn-ternary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ternary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(215, 220, 222, 0.5); }

.btn-outline-primary {
  color: #1f2041;
  border-color: #1f2041; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #1f2041;
    border-color: #1f2041; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(31, 32, 65, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #1f2041;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #1f2041;
    border-color: #1f2041; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(31, 32, 65, 0.5); }

.btn-outline-secondary {
  color: #2cab6c;
  border-color: #2cab6c; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #2cab6c;
    border-color: #2cab6c; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 171, 108, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #2cab6c;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #2cab6c;
    border-color: #2cab6c; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(44, 171, 108, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #fff;
  border-color: #fff; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-dark {
  color: #212121;
  border-color: #212121; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #212121;
    border-color: #212121; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #212121;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #212121;
    border-color: #212121; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5); }

.btn-outline-ternary {
  color: #f7fcfe;
  border-color: #f7fcfe; }
  .btn-outline-ternary:hover {
    color: #212529;
    background-color: #f7fcfe;
    border-color: #f7fcfe; }
  .btn-outline-ternary:focus, .btn-outline-ternary.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 252, 254, 0.5); }
  .btn-outline-ternary.disabled, .btn-outline-ternary:disabled {
    color: #f7fcfe;
    background-color: transparent; }
  .btn-outline-ternary:not(:disabled):not(.disabled):active, .btn-outline-ternary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ternary.dropdown-toggle {
    color: #212529;
    background-color: #f7fcfe;
    border-color: #f7fcfe; }
    .btn-outline-ternary:not(:disabled):not(.disabled):active:focus, .btn-outline-ternary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ternary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(247, 252, 254, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2cab6c;
  text-decoration: none; }
  .btn-link:hover {
    color: #1f2041;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, #login .button.button-primary {
  padding: 1rem 3rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 5px; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 5px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.form-control, #login #user_login,
#login #user_pass {
  display: block;
  width: 100%;
  height: calc(1.5em + 2rem + 4px);
  padding: 1rem 1rem;
  font-family: "Avenir LT W01_45 Book1475508", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.5;
  color: #1f2041;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #e4f5fe;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, #login #user_login,
    #login #user_pass {
      transition: none; } }
  .form-control::-ms-expand, #login #user_login::-ms-expand,
  #login #user_pass::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, #login #user_login:focus,
  #login #user_pass:focus {
    color: #1f2041;
    background-color: #fff;
    border-color: #7addac;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 171, 108, 0.25); }
  .form-control::placeholder, #login #user_login::placeholder,
  #login #user_pass::placeholder {
    color: rgba(31, 32, 65, 0.75);
    opacity: 1; }
  .form-control:disabled, #login #user_login:disabled,
  #login #user_pass:disabled, .form-control[readonly], #login #user_login[readonly],
  #login #user_pass[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value, #login select#user_login:focus::-ms-value,
#login select#user_pass:focus::-ms-value {
  color: #1f2041;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(1rem + 2px);
  padding-bottom: calc(1rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212121;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 5px; }

.form-control-lg {
  height: calc(1.5em + 1rem + 4px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 5px; }

select.form-control[size], #login select#user_login[size],
#login select#user_pass[size], select.form-control[multiple], #login select#user_login[multiple],
#login select#user_pass[multiple] {
  height: auto; }

textarea.form-control, #login textarea#user_login,
#login textarea#user_pass {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row, #login .login-username,
#login .login-password {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col, #login .login-username > .col, #login .login-password > .col,
  .form-row > [class*="col-"],
  #login .login-username > [class*="col-"],
  #login .login-password > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 5px; }

.was-validated .form-control:valid, .was-validated #login #user_login:valid, #login .was-validated #user_login:valid,
.was-validated #login #user_pass:valid, #login .was-validated #user_pass:valid, .form-control.is-valid, #login .is-valid#user_login,
#login .is-valid#user_pass {
  border-color: #28a745;
  padding-right: calc(1.5em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.5rem);
  background-size: calc(0.75em + 1rem) calc(0.75em + 1rem); }
  .was-validated .form-control:valid:focus, .was-validated #login #user_login:valid:focus, #login .was-validated #user_login:valid:focus,
  .was-validated #login #user_pass:valid:focus, #login .was-validated #user_pass:valid:focus, .form-control.is-valid:focus, #login .is-valid#user_login:focus,
  #login .is-valid#user_pass:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback, .was-validated #login #user_login:valid ~ .valid-feedback, #login .was-validated #user_login:valid ~ .valid-feedback, .was-validated #login #user_pass:valid ~ .valid-feedback, #login .was-validated #user_pass:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .was-validated #login #user_login:valid ~ .valid-tooltip,
  #login .was-validated #user_login:valid ~ .valid-tooltip,
  .was-validated #login #user_pass:valid ~ .valid-tooltip,
  #login .was-validated #user_pass:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, #login .is-valid#user_login ~ .valid-feedback, #login .is-valid#user_pass ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  #login .is-valid#user_login ~ .valid-tooltip,
  #login .is-valid#user_pass ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, .was-validated #login textarea#user_login:valid, #login .was-validated textarea#user_login:valid,
.was-validated #login textarea#user_pass:valid, #login .was-validated textarea#user_pass:valid, textarea.form-control.is-valid, #login textarea.is-valid#user_login,
#login textarea.is-valid#user_pass {
  padding-right: calc(1.5em + 2rem);
  background-position: top calc(0.375em + 0.5rem) right calc(0.375em + 0.5rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 2rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3e%3cpath fill='%231f2041' d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z'/%3e%3c/svg%3e") no-repeat right 1rem center/16px 20px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.75em + 1rem) calc(0.75em + 1rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 5px; }

.was-validated .form-control:invalid, .was-validated #login #user_login:invalid, #login .was-validated #user_login:invalid,
.was-validated #login #user_pass:invalid, #login .was-validated #user_pass:invalid, .form-control.is-invalid, #login .is-invalid#user_login,
#login .is-invalid#user_pass {
  border-color: #dc3545;
  padding-right: calc(1.5em + 2rem);
  background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" class="svg-inline--fa fa-exclamation-triangle fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="red" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>');
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.5rem);
  background-size: calc(0.75em + 1rem) calc(0.75em + 1rem); }
  .was-validated .form-control:invalid:focus, .was-validated #login #user_login:invalid:focus, #login .was-validated #user_login:invalid:focus,
  .was-validated #login #user_pass:invalid:focus, #login .was-validated #user_pass:invalid:focus, .form-control.is-invalid:focus, #login .is-invalid#user_login:focus,
  #login .is-invalid#user_pass:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated #login #user_login:invalid ~ .invalid-feedback, #login .was-validated #user_login:invalid ~ .invalid-feedback, .was-validated #login #user_pass:invalid ~ .invalid-feedback, #login .was-validated #user_pass:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .was-validated #login #user_login:invalid ~ .invalid-tooltip,
  #login .was-validated #user_login:invalid ~ .invalid-tooltip,
  .was-validated #login #user_pass:invalid ~ .invalid-tooltip,
  #login .was-validated #user_pass:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, #login .is-invalid#user_login ~ .invalid-feedback, #login .is-invalid#user_pass ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  #login .is-invalid#user_login ~ .invalid-tooltip,
  #login .is-invalid#user_pass ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, .was-validated #login textarea#user_login:invalid, #login .was-validated textarea#user_login:invalid,
.was-validated #login textarea#user_pass:invalid, #login .was-validated textarea#user_pass:invalid, textarea.form-control.is-invalid, #login textarea.is-invalid#user_login,
#login textarea.is-invalid#user_pass {
  padding-right: calc(1.5em + 2rem);
  background-position: top calc(0.375em + 0.5rem) right calc(0.375em + 0.5rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 2rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3e%3cpath fill='%231f2041' d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z'/%3e%3c/svg%3e") no-repeat right 1rem center/16px 20px, url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" class="svg-inline--fa fa-exclamation-triangle fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="red" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>') #fff no-repeat center right 2rem/calc(0.75em + 1rem) calc(0.75em + 1rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline #login #user_login, #login .form-inline #user_login,
    .form-inline #login #user_pass, #login .form-inline #user_pass {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212121; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid rgba(255, 255, 255, 0.15); }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(255, 255, 255, 0.15); }
  .table tbody + tbody {
    border-top: 2px solid rgba(255, 255, 255, 0.15); }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid rgba(255, 255, 255, 0.15); }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid rgba(255, 255, 255, 0.15); }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212121;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c0c1ca; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8b8b9c; }

.table-hover .table-primary:hover {
  background-color: #b2b3be; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b2b3be; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c4e7d6; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #91d3b3; }

.table-hover .table-secondary:hover {
  background-color: #b2e0c9; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #b2e0c9; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: white; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: white; }

.table-hover .table-light:hover {
  background-color: #f2f2f2; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f2f2f2; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c1c1c1; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8c8c8c; }

.table-hover .table-dark:hover {
  background-color: #b4b4b4; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b4b4b4; }

.table-ternary,
.table-ternary > th,
.table-ternary > td {
  background-color: #fdfeff; }

.table-ternary th,
.table-ternary td,
.table-ternary thead th,
.table-ternary tbody + tbody {
  border-color: #fbfdfe; }

.table-hover .table-ternary:hover {
  background-color: #e4f1ff; }
  .table-hover .table-ternary:hover > td,
  .table-hover .table-ternary:hover > th {
    background-color: #e4f1ff; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(255, 255, 255, 0.15); }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1899.98px) {
  .table-responsive-fhd {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-fhd > .table-bordered {
      border: 0; } }

@media (max-width: 2539.98px) {
  .table-responsive-qhd {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-qhd > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2cab6c;
    background-color: #2cab6c; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(44, 171, 108, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #7addac; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #a2e7c5;
    border-color: #a2e7c5; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #2cab6c solid 2px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 5px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2cab6c;
  background-color: #2cab6c; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(31, 32, 65, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(31, 32, 65, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(31, 32, 65, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 4px);
    left: calc(-2.25rem + 4px);
    width: calc(1rem - 8px);
    height: calc(1rem - 8px);
    background-color: #2cab6c;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(31, 32, 65, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 2rem + 4px);
  padding: 1rem 2rem 1rem 1rem;
  font-family: "Avenir LT W01_45 Book1475508", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.5;
  color: #1f2041;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3e%3cpath fill='%231f2041' d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z'/%3e%3c/svg%3e") no-repeat right 1rem center/16px 20px;
  background-color: #fff;
  border: 2px solid #e4f5fe;
  border-radius: 5px;
  appearance: none; }
  .custom-select:focus {
    border-color: #7addac;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 171, 108, 0.25); }
    .custom-select:focus::-ms-value {
      color: #1f2041;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 4px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 2rem + 4px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 2rem + 4px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #7addac;
    box-shadow: 0 0 0 0.2rem rgba(44, 171, 108, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 2rem + 4px);
  padding: 1rem 1rem;
  font-family: "Avenir LT W01_45 Book1475508", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  line-height: 1.5;
  color: #1f2041;
  background-color: #fff;
  border: 2px solid #e4f5fe;
  border-radius: 5px; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 2rem);
    padding: 1rem 1rem;
    line-height: 1.5;
    color: #1f2041;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 5px 5px 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(44, 171, 108, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(44, 171, 108, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(44, 171, 108, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2cab6c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #a2e7c5; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2cab6c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #a2e7c5; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #2cab6c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #a2e7c5; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 5px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2cab6c; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(5px - 1px) calc(5px - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(5px - 1px) calc(5px - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(5px - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(5px - 1px);
  border-top-right-radius: calc(5px - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(5px - 1px);
  border-bottom-left-radius: calc(5px - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 5px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #101122;
  background-color: #d2d2d9;
  border-color: #c0c1ca; }
  .alert-primary hr {
    border-top-color: #b2b3be; }
  .alert-primary .alert-link {
    color: black; }

.alert-secondary {
  color: #175938;
  background-color: #d5eee2;
  border-color: #c4e7d6; }
  .alert-secondary hr {
    border-top-color: #b2e0c9; }
  .alert-secondary .alert-link {
    color: #0d301f; }

.alert-success, .alert--success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr, .alert--success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link, .alert--success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger, .alert--danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr, .alert--danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link, .alert--danger .alert-link {
    color: #491217; }

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-light hr {
    border-top-color: #f2f2f2; }
  .alert-light .alert-link {
    color: #6c6c6c; }

.alert-dark {
  color: #111111;
  background-color: lightgray;
  border-color: #c1c1c1; }
  .alert-dark hr {
    border-top-color: #b4b4b4; }
  .alert-dark .alert-link {
    color: black; }

.alert-ternary {
  color: #808384;
  background-color: #fdfeff;
  border-color: #fdfeff; }
  .alert-ternary hr {
    border-top-color: #e4f1ff; }
  .alert-ternary .alert-link {
    color: #67696a; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212121;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2cab6c;
    border-color: #2cab6c; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 0; } }

@media (min-width: 1900px) {
  .list-group-horizontal-fhd {
    flex-direction: row; }
    .list-group-horizontal-fhd .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-fhd .list-group-item:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0; }
      .list-group-horizontal-fhd .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 0; } }

@media (min-width: 2540px) {
  .list-group-horizontal-qhd {
    flex-direction: row; }
    .list-group-horizontal-qhd .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-qhd .list-group-item:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0; }
      .list-group-horizontal-qhd .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #101122;
  background-color: #c0c1ca; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #101122;
    background-color: #b2b3be; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #101122;
    border-color: #101122; }

.list-group-item-secondary {
  color: #175938;
  background-color: #c4e7d6; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #175938;
    background-color: #b2e0c9; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #175938;
    border-color: #175938; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #858585;
  background-color: white; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-dark {
  color: #111111;
  background-color: #c1c1c1; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #111111;
    background-color: #b4b4b4; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #111111;
    border-color: #111111; }

.list-group-item-ternary {
  color: #808384;
  background-color: #fdfeff; }
  .list-group-item-ternary.list-group-item-action:hover, .list-group-item-ternary.list-group-item-action:focus {
    color: #808384;
    background-color: #e4f1ff; }
  .list-group-item-ternary.list-group-item-action.active {
    color: #fff;
    background-color: #808384;
    border-color: #808384; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #1f2041 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0f0f1e !important; }

.bg-secondary {
  background-color: #2cab6c !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #228252 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #fff !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important; }

.bg-dark {
  background-color: #212121 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #080808 !important; }

.bg-ternary {
  background-color: #f7fcfe !important; }

a.bg-ternary:hover, a.bg-ternary:focus,
button.bg-ternary:hover,
button.bg-ternary:focus {
  background-color: #caebf8 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid rgba(255, 255, 255, 0.15) !important; }

.border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.15) !important; }

.border-right {
  border-right: 1px solid rgba(255, 255, 255, 0.15) !important; }

.border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important; }

.border-left {
  border-left: 1px solid rgba(255, 255, 255, 0.15) !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #1f2041 !important; }

.border-secondary {
  border-color: #2cab6c !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #fff !important; }

.border-dark {
  border-color: #212121 !important; }

.border-ternary {
  border-color: #f7fcfe !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 5px !important; }

.rounded {
  border-radius: 5px !important; }

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important; }

.rounded-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important; }

.rounded-bottom {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important; }

.rounded-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; }

.rounded-lg {
  border-radius: 5px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1900px) {
  .d-fhd-none {
    display: none !important; }
  .d-fhd-inline {
    display: inline !important; }
  .d-fhd-inline-block {
    display: inline-block !important; }
  .d-fhd-block {
    display: block !important; }
  .d-fhd-table {
    display: table !important; }
  .d-fhd-table-row {
    display: table-row !important; }
  .d-fhd-table-cell {
    display: table-cell !important; }
  .d-fhd-flex {
    display: flex !important; }
  .d-fhd-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 2540px) {
  .d-qhd-none {
    display: none !important; }
  .d-qhd-inline {
    display: inline !important; }
  .d-qhd-inline-block {
    display: inline-block !important; }
  .d-qhd-block {
    display: block !important; }
  .d-qhd-table {
    display: table !important; }
  .d-qhd-table-row {
    display: table-row !important; }
  .d-qhd-table-cell {
    display: table-cell !important; }
  .d-qhd-flex {
    display: flex !important; }
  .d-qhd-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1900px) {
  .flex-fhd-row {
    flex-direction: row !important; }
  .flex-fhd-column {
    flex-direction: column !important; }
  .flex-fhd-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-fhd-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-fhd-wrap {
    flex-wrap: wrap !important; }
  .flex-fhd-nowrap {
    flex-wrap: nowrap !important; }
  .flex-fhd-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-fhd-fill {
    flex: 1 1 auto !important; }
  .flex-fhd-grow-0 {
    flex-grow: 0 !important; }
  .flex-fhd-grow-1 {
    flex-grow: 1 !important; }
  .flex-fhd-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-fhd-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-fhd-start {
    justify-content: flex-start !important; }
  .justify-content-fhd-end {
    justify-content: flex-end !important; }
  .justify-content-fhd-center {
    justify-content: center !important; }
  .justify-content-fhd-between {
    justify-content: space-between !important; }
  .justify-content-fhd-around {
    justify-content: space-around !important; }
  .align-items-fhd-start {
    align-items: flex-start !important; }
  .align-items-fhd-end {
    align-items: flex-end !important; }
  .align-items-fhd-center {
    align-items: center !important; }
  .align-items-fhd-baseline {
    align-items: baseline !important; }
  .align-items-fhd-stretch {
    align-items: stretch !important; }
  .align-content-fhd-start {
    align-content: flex-start !important; }
  .align-content-fhd-end {
    align-content: flex-end !important; }
  .align-content-fhd-center {
    align-content: center !important; }
  .align-content-fhd-between {
    align-content: space-between !important; }
  .align-content-fhd-around {
    align-content: space-around !important; }
  .align-content-fhd-stretch {
    align-content: stretch !important; }
  .align-self-fhd-auto {
    align-self: auto !important; }
  .align-self-fhd-start {
    align-self: flex-start !important; }
  .align-self-fhd-end {
    align-self: flex-end !important; }
  .align-self-fhd-center {
    align-self: center !important; }
  .align-self-fhd-baseline {
    align-self: baseline !important; }
  .align-self-fhd-stretch {
    align-self: stretch !important; } }

@media (min-width: 2540px) {
  .flex-qhd-row {
    flex-direction: row !important; }
  .flex-qhd-column {
    flex-direction: column !important; }
  .flex-qhd-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-qhd-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-qhd-wrap {
    flex-wrap: wrap !important; }
  .flex-qhd-nowrap {
    flex-wrap: nowrap !important; }
  .flex-qhd-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-qhd-fill {
    flex: 1 1 auto !important; }
  .flex-qhd-grow-0 {
    flex-grow: 0 !important; }
  .flex-qhd-grow-1 {
    flex-grow: 1 !important; }
  .flex-qhd-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-qhd-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-qhd-start {
    justify-content: flex-start !important; }
  .justify-content-qhd-end {
    justify-content: flex-end !important; }
  .justify-content-qhd-center {
    justify-content: center !important; }
  .justify-content-qhd-between {
    justify-content: space-between !important; }
  .justify-content-qhd-around {
    justify-content: space-around !important; }
  .align-items-qhd-start {
    align-items: flex-start !important; }
  .align-items-qhd-end {
    align-items: flex-end !important; }
  .align-items-qhd-center {
    align-items: center !important; }
  .align-items-qhd-baseline {
    align-items: baseline !important; }
  .align-items-qhd-stretch {
    align-items: stretch !important; }
  .align-content-qhd-start {
    align-content: flex-start !important; }
  .align-content-qhd-end {
    align-content: flex-end !important; }
  .align-content-qhd-center {
    align-content: center !important; }
  .align-content-qhd-between {
    align-content: space-between !important; }
  .align-content-qhd-around {
    align-content: space-around !important; }
  .align-content-qhd-stretch {
    align-content: stretch !important; }
  .align-self-qhd-auto {
    align-self: auto !important; }
  .align-self-qhd-start {
    align-self: flex-start !important; }
  .align-self-qhd-end {
    align-self: flex-end !important; }
  .align-self-qhd-center {
    align-self: center !important; }
  .align-self-qhd-baseline {
    align-self: baseline !important; }
  .align-self-qhd-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1900px) {
  .float-fhd-left {
    float: left !important; }
  .float-fhd-right {
    float: right !important; }
  .float-fhd-none {
    float: none !important; } }

@media (min-width: 2540px) {
  .float-qhd-left {
    float: left !important; }
  .float-qhd-right {
    float: right !important; }
  .float-qhd-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 2rem !important; }

.mt-5,
.my-5 {
  margin-top: 2rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 2rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2rem !important; }

.m-6 {
  margin: 3rem !important; }

.mt-6,
.my-6 {
  margin-top: 3rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3rem !important; }

.m-7 {
  margin: 4rem !important; }

.mt-7,
.my-7 {
  margin-top: 4rem !important; }

.mr-7,
.mx-7 {
  margin-right: 4rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 4rem !important; }

.ml-7,
.mx-7 {
  margin-left: 4rem !important; }

.m-8 {
  margin: 5rem !important; }

.mt-8,
.my-8 {
  margin-top: 5rem !important; }

.mr-8,
.mx-8 {
  margin-right: 5rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 5rem !important; }

.ml-8,
.mx-8 {
  margin-left: 5rem !important; }

.m-9 {
  margin: 6rem !important; }

.mt-9,
.my-9 {
  margin-top: 6rem !important; }

.mr-9,
.mx-9 {
  margin-right: 6rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 6rem !important; }

.ml-9,
.mx-9 {
  margin-left: 6rem !important; }

.m-10 {
  margin: 7rem !important; }

.mt-10,
.my-10 {
  margin-top: 7rem !important; }

.mr-10,
.mx-10 {
  margin-right: 7rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 7rem !important; }

.ml-10,
.mx-10 {
  margin-left: 7rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 2rem !important; }

.pt-5,
.py-5 {
  padding-top: 2rem !important; }

.pr-5,
.px-5 {
  padding-right: 2rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 2rem !important; }

.pl-5,
.px-5 {
  padding-left: 2rem !important; }

.p-6 {
  padding: 3rem !important; }

.pt-6,
.py-6 {
  padding-top: 3rem !important; }

.pr-6,
.px-6 {
  padding-right: 3rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3rem !important; }

.pl-6,
.px-6 {
  padding-left: 3rem !important; }

.p-7 {
  padding: 4rem !important; }

.pt-7,
.py-7 {
  padding-top: 4rem !important; }

.pr-7,
.px-7 {
  padding-right: 4rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 4rem !important; }

.pl-7,
.px-7 {
  padding-left: 4rem !important; }

.p-8 {
  padding: 5rem !important; }

.pt-8,
.py-8 {
  padding-top: 5rem !important; }

.pr-8,
.px-8 {
  padding-right: 5rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 5rem !important; }

.pl-8,
.px-8 {
  padding-left: 5rem !important; }

.p-9 {
  padding: 6rem !important; }

.pt-9,
.py-9 {
  padding-top: 6rem !important; }

.pr-9,
.px-9 {
  padding-right: 6rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 6rem !important; }

.pl-9,
.px-9 {
  padding-left: 6rem !important; }

.p-10 {
  padding: 7rem !important; }

.pt-10,
.py-10 {
  padding-top: 7rem !important; }

.pr-10,
.px-10 {
  padding-right: 7rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 7rem !important; }

.pl-10,
.px-10 {
  padding-left: 7rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.25rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.25rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.25rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.25rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.25rem !important; }

.m-n3 {
  margin: -0.25rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -0.25rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -0.25rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -0.25rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -0.25rem !important; }

.m-n4 {
  margin: -0.25rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -0.25rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -0.25rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -0.25rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -0.25rem !important; }

.m-n5 {
  margin: -0.25rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -0.25rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -0.25rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -0.25rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -0.25rem !important; }

.m-n6 {
  margin: -0.25rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -0.25rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -0.25rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -0.25rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -0.25rem !important; }

.m-n7 {
  margin: -0.25rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -0.25rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -0.25rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -0.25rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -0.25rem !important; }

.m-n8 {
  margin: -0.25rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -0.25rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -0.25rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -0.25rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -0.25rem !important; }

.m-n9 {
  margin: -0.25rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -0.25rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -0.25rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -0.25rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -0.25rem !important; }

.m-n10 {
  margin: -0.25rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -0.25rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -0.25rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -0.25rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -0.25rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 2rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important; }
  .m-sm-6 {
    margin: 3rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3rem !important; }
  .m-sm-7 {
    margin: 4rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 4rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 4rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 4rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 4rem !important; }
  .m-sm-8 {
    margin: 5rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 5rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 5rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 5rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 5rem !important; }
  .m-sm-9 {
    margin: 6rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 6rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 6rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 6rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 6rem !important; }
  .m-sm-10 {
    margin: 7rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 7rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 7rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 7rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 7rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 2rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important; }
  .p-sm-6 {
    padding: 3rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3rem !important; }
  .p-sm-7 {
    padding: 4rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 4rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 4rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 4rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 4rem !important; }
  .p-sm-8 {
    padding: 5rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 5rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 5rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 5rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 5rem !important; }
  .p-sm-9 {
    padding: 6rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 6rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 6rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 6rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 6rem !important; }
  .p-sm-10 {
    padding: 7rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 7rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 7rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 7rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 7rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.25rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.25rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.25rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.25rem !important; }
  .m-sm-n3 {
    margin: -0.25rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.25rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.25rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.25rem !important; }
  .m-sm-n4 {
    margin: -0.25rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -0.25rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -0.25rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -0.25rem !important; }
  .m-sm-n5 {
    margin: -0.25rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -0.25rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -0.25rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -0.25rem !important; }
  .m-sm-n6 {
    margin: -0.25rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -0.25rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -0.25rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -0.25rem !important; }
  .m-sm-n7 {
    margin: -0.25rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -0.25rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -0.25rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -0.25rem !important; }
  .m-sm-n8 {
    margin: -0.25rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -0.25rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -0.25rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -0.25rem !important; }
  .m-sm-n9 {
    margin: -0.25rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -0.25rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -0.25rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -0.25rem !important; }
  .m-sm-n10 {
    margin: -0.25rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -0.25rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -0.25rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -0.25rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 2rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important; }
  .m-md-6 {
    margin: 3rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3rem !important; }
  .m-md-7 {
    margin: 4rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 4rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 4rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 4rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 4rem !important; }
  .m-md-8 {
    margin: 5rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 5rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 5rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 5rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 5rem !important; }
  .m-md-9 {
    margin: 6rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 6rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 6rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 6rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 6rem !important; }
  .m-md-10 {
    margin: 7rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 7rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 7rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 7rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 7rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 2rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important; }
  .p-md-6 {
    padding: 3rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3rem !important; }
  .p-md-7 {
    padding: 4rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 4rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 4rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 4rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 4rem !important; }
  .p-md-8 {
    padding: 5rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 5rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 5rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 5rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 5rem !important; }
  .p-md-9 {
    padding: 6rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 6rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 6rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 6rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 6rem !important; }
  .p-md-10 {
    padding: 7rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 7rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 7rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 7rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 7rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.25rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.25rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.25rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.25rem !important; }
  .m-md-n3 {
    margin: -0.25rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.25rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.25rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.25rem !important; }
  .m-md-n4 {
    margin: -0.25rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -0.25rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -0.25rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -0.25rem !important; }
  .m-md-n5 {
    margin: -0.25rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -0.25rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -0.25rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -0.25rem !important; }
  .m-md-n6 {
    margin: -0.25rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -0.25rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -0.25rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -0.25rem !important; }
  .m-md-n7 {
    margin: -0.25rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -0.25rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -0.25rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -0.25rem !important; }
  .m-md-n8 {
    margin: -0.25rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -0.25rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -0.25rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -0.25rem !important; }
  .m-md-n9 {
    margin: -0.25rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -0.25rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -0.25rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -0.25rem !important; }
  .m-md-n10 {
    margin: -0.25rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -0.25rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -0.25rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -0.25rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 2rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important; }
  .m-lg-6 {
    margin: 3rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3rem !important; }
  .m-lg-7 {
    margin: 4rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 4rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 4rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 4rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 4rem !important; }
  .m-lg-8 {
    margin: 5rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 5rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 5rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 5rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 5rem !important; }
  .m-lg-9 {
    margin: 6rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 6rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 6rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 6rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 6rem !important; }
  .m-lg-10 {
    margin: 7rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 7rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 7rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 7rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 7rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 2rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important; }
  .p-lg-6 {
    padding: 3rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3rem !important; }
  .p-lg-7 {
    padding: 4rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 4rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 4rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 4rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 4rem !important; }
  .p-lg-8 {
    padding: 5rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 5rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 5rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 5rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 5rem !important; }
  .p-lg-9 {
    padding: 6rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 6rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 6rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 6rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 6rem !important; }
  .p-lg-10 {
    padding: 7rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 7rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 7rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 7rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 7rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.25rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.25rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.25rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.25rem !important; }
  .m-lg-n3 {
    margin: -0.25rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.25rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.25rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.25rem !important; }
  .m-lg-n4 {
    margin: -0.25rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -0.25rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -0.25rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -0.25rem !important; }
  .m-lg-n5 {
    margin: -0.25rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -0.25rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -0.25rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -0.25rem !important; }
  .m-lg-n6 {
    margin: -0.25rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -0.25rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -0.25rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -0.25rem !important; }
  .m-lg-n7 {
    margin: -0.25rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -0.25rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -0.25rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -0.25rem !important; }
  .m-lg-n8 {
    margin: -0.25rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -0.25rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -0.25rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -0.25rem !important; }
  .m-lg-n9 {
    margin: -0.25rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -0.25rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -0.25rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -0.25rem !important; }
  .m-lg-n10 {
    margin: -0.25rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -0.25rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -0.25rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -0.25rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 2rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important; }
  .m-xl-6 {
    margin: 3rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3rem !important; }
  .m-xl-7 {
    margin: 4rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 4rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 4rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 4rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 4rem !important; }
  .m-xl-8 {
    margin: 5rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 5rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 5rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 5rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 5rem !important; }
  .m-xl-9 {
    margin: 6rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 6rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 6rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 6rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 6rem !important; }
  .m-xl-10 {
    margin: 7rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 7rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 7rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 7rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 7rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 2rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important; }
  .p-xl-6 {
    padding: 3rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3rem !important; }
  .p-xl-7 {
    padding: 4rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 4rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 4rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 4rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 4rem !important; }
  .p-xl-8 {
    padding: 5rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 5rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 5rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 5rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 5rem !important; }
  .p-xl-9 {
    padding: 6rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 6rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 6rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 6rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 6rem !important; }
  .p-xl-10 {
    padding: 7rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 7rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 7rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 7rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 7rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.25rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.25rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.25rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.25rem !important; }
  .m-xl-n3 {
    margin: -0.25rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.25rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.25rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.25rem !important; }
  .m-xl-n4 {
    margin: -0.25rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -0.25rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -0.25rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -0.25rem !important; }
  .m-xl-n5 {
    margin: -0.25rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -0.25rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -0.25rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -0.25rem !important; }
  .m-xl-n6 {
    margin: -0.25rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -0.25rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -0.25rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -0.25rem !important; }
  .m-xl-n7 {
    margin: -0.25rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -0.25rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -0.25rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -0.25rem !important; }
  .m-xl-n8 {
    margin: -0.25rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -0.25rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -0.25rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -0.25rem !important; }
  .m-xl-n9 {
    margin: -0.25rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -0.25rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -0.25rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -0.25rem !important; }
  .m-xl-n10 {
    margin: -0.25rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -0.25rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -0.25rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -0.25rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1900px) {
  .m-fhd-0 {
    margin: 0 !important; }
  .mt-fhd-0,
  .my-fhd-0 {
    margin-top: 0 !important; }
  .mr-fhd-0,
  .mx-fhd-0 {
    margin-right: 0 !important; }
  .mb-fhd-0,
  .my-fhd-0 {
    margin-bottom: 0 !important; }
  .ml-fhd-0,
  .mx-fhd-0 {
    margin-left: 0 !important; }
  .m-fhd-1 {
    margin: 0.25rem !important; }
  .mt-fhd-1,
  .my-fhd-1 {
    margin-top: 0.25rem !important; }
  .mr-fhd-1,
  .mx-fhd-1 {
    margin-right: 0.25rem !important; }
  .mb-fhd-1,
  .my-fhd-1 {
    margin-bottom: 0.25rem !important; }
  .ml-fhd-1,
  .mx-fhd-1 {
    margin-left: 0.25rem !important; }
  .m-fhd-2 {
    margin: 0.5rem !important; }
  .mt-fhd-2,
  .my-fhd-2 {
    margin-top: 0.5rem !important; }
  .mr-fhd-2,
  .mx-fhd-2 {
    margin-right: 0.5rem !important; }
  .mb-fhd-2,
  .my-fhd-2 {
    margin-bottom: 0.5rem !important; }
  .ml-fhd-2,
  .mx-fhd-2 {
    margin-left: 0.5rem !important; }
  .m-fhd-3 {
    margin: 1rem !important; }
  .mt-fhd-3,
  .my-fhd-3 {
    margin-top: 1rem !important; }
  .mr-fhd-3,
  .mx-fhd-3 {
    margin-right: 1rem !important; }
  .mb-fhd-3,
  .my-fhd-3 {
    margin-bottom: 1rem !important; }
  .ml-fhd-3,
  .mx-fhd-3 {
    margin-left: 1rem !important; }
  .m-fhd-4 {
    margin: 1.5rem !important; }
  .mt-fhd-4,
  .my-fhd-4 {
    margin-top: 1.5rem !important; }
  .mr-fhd-4,
  .mx-fhd-4 {
    margin-right: 1.5rem !important; }
  .mb-fhd-4,
  .my-fhd-4 {
    margin-bottom: 1.5rem !important; }
  .ml-fhd-4,
  .mx-fhd-4 {
    margin-left: 1.5rem !important; }
  .m-fhd-5 {
    margin: 2rem !important; }
  .mt-fhd-5,
  .my-fhd-5 {
    margin-top: 2rem !important; }
  .mr-fhd-5,
  .mx-fhd-5 {
    margin-right: 2rem !important; }
  .mb-fhd-5,
  .my-fhd-5 {
    margin-bottom: 2rem !important; }
  .ml-fhd-5,
  .mx-fhd-5 {
    margin-left: 2rem !important; }
  .m-fhd-6 {
    margin: 3rem !important; }
  .mt-fhd-6,
  .my-fhd-6 {
    margin-top: 3rem !important; }
  .mr-fhd-6,
  .mx-fhd-6 {
    margin-right: 3rem !important; }
  .mb-fhd-6,
  .my-fhd-6 {
    margin-bottom: 3rem !important; }
  .ml-fhd-6,
  .mx-fhd-6 {
    margin-left: 3rem !important; }
  .m-fhd-7 {
    margin: 4rem !important; }
  .mt-fhd-7,
  .my-fhd-7 {
    margin-top: 4rem !important; }
  .mr-fhd-7,
  .mx-fhd-7 {
    margin-right: 4rem !important; }
  .mb-fhd-7,
  .my-fhd-7 {
    margin-bottom: 4rem !important; }
  .ml-fhd-7,
  .mx-fhd-7 {
    margin-left: 4rem !important; }
  .m-fhd-8 {
    margin: 5rem !important; }
  .mt-fhd-8,
  .my-fhd-8 {
    margin-top: 5rem !important; }
  .mr-fhd-8,
  .mx-fhd-8 {
    margin-right: 5rem !important; }
  .mb-fhd-8,
  .my-fhd-8 {
    margin-bottom: 5rem !important; }
  .ml-fhd-8,
  .mx-fhd-8 {
    margin-left: 5rem !important; }
  .m-fhd-9 {
    margin: 6rem !important; }
  .mt-fhd-9,
  .my-fhd-9 {
    margin-top: 6rem !important; }
  .mr-fhd-9,
  .mx-fhd-9 {
    margin-right: 6rem !important; }
  .mb-fhd-9,
  .my-fhd-9 {
    margin-bottom: 6rem !important; }
  .ml-fhd-9,
  .mx-fhd-9 {
    margin-left: 6rem !important; }
  .m-fhd-10 {
    margin: 7rem !important; }
  .mt-fhd-10,
  .my-fhd-10 {
    margin-top: 7rem !important; }
  .mr-fhd-10,
  .mx-fhd-10 {
    margin-right: 7rem !important; }
  .mb-fhd-10,
  .my-fhd-10 {
    margin-bottom: 7rem !important; }
  .ml-fhd-10,
  .mx-fhd-10 {
    margin-left: 7rem !important; }
  .p-fhd-0 {
    padding: 0 !important; }
  .pt-fhd-0,
  .py-fhd-0 {
    padding-top: 0 !important; }
  .pr-fhd-0,
  .px-fhd-0 {
    padding-right: 0 !important; }
  .pb-fhd-0,
  .py-fhd-0 {
    padding-bottom: 0 !important; }
  .pl-fhd-0,
  .px-fhd-0 {
    padding-left: 0 !important; }
  .p-fhd-1 {
    padding: 0.25rem !important; }
  .pt-fhd-1,
  .py-fhd-1 {
    padding-top: 0.25rem !important; }
  .pr-fhd-1,
  .px-fhd-1 {
    padding-right: 0.25rem !important; }
  .pb-fhd-1,
  .py-fhd-1 {
    padding-bottom: 0.25rem !important; }
  .pl-fhd-1,
  .px-fhd-1 {
    padding-left: 0.25rem !important; }
  .p-fhd-2 {
    padding: 0.5rem !important; }
  .pt-fhd-2,
  .py-fhd-2 {
    padding-top: 0.5rem !important; }
  .pr-fhd-2,
  .px-fhd-2 {
    padding-right: 0.5rem !important; }
  .pb-fhd-2,
  .py-fhd-2 {
    padding-bottom: 0.5rem !important; }
  .pl-fhd-2,
  .px-fhd-2 {
    padding-left: 0.5rem !important; }
  .p-fhd-3 {
    padding: 1rem !important; }
  .pt-fhd-3,
  .py-fhd-3 {
    padding-top: 1rem !important; }
  .pr-fhd-3,
  .px-fhd-3 {
    padding-right: 1rem !important; }
  .pb-fhd-3,
  .py-fhd-3 {
    padding-bottom: 1rem !important; }
  .pl-fhd-3,
  .px-fhd-3 {
    padding-left: 1rem !important; }
  .p-fhd-4 {
    padding: 1.5rem !important; }
  .pt-fhd-4,
  .py-fhd-4 {
    padding-top: 1.5rem !important; }
  .pr-fhd-4,
  .px-fhd-4 {
    padding-right: 1.5rem !important; }
  .pb-fhd-4,
  .py-fhd-4 {
    padding-bottom: 1.5rem !important; }
  .pl-fhd-4,
  .px-fhd-4 {
    padding-left: 1.5rem !important; }
  .p-fhd-5 {
    padding: 2rem !important; }
  .pt-fhd-5,
  .py-fhd-5 {
    padding-top: 2rem !important; }
  .pr-fhd-5,
  .px-fhd-5 {
    padding-right: 2rem !important; }
  .pb-fhd-5,
  .py-fhd-5 {
    padding-bottom: 2rem !important; }
  .pl-fhd-5,
  .px-fhd-5 {
    padding-left: 2rem !important; }
  .p-fhd-6 {
    padding: 3rem !important; }
  .pt-fhd-6,
  .py-fhd-6 {
    padding-top: 3rem !important; }
  .pr-fhd-6,
  .px-fhd-6 {
    padding-right: 3rem !important; }
  .pb-fhd-6,
  .py-fhd-6 {
    padding-bottom: 3rem !important; }
  .pl-fhd-6,
  .px-fhd-6 {
    padding-left: 3rem !important; }
  .p-fhd-7 {
    padding: 4rem !important; }
  .pt-fhd-7,
  .py-fhd-7 {
    padding-top: 4rem !important; }
  .pr-fhd-7,
  .px-fhd-7 {
    padding-right: 4rem !important; }
  .pb-fhd-7,
  .py-fhd-7 {
    padding-bottom: 4rem !important; }
  .pl-fhd-7,
  .px-fhd-7 {
    padding-left: 4rem !important; }
  .p-fhd-8 {
    padding: 5rem !important; }
  .pt-fhd-8,
  .py-fhd-8 {
    padding-top: 5rem !important; }
  .pr-fhd-8,
  .px-fhd-8 {
    padding-right: 5rem !important; }
  .pb-fhd-8,
  .py-fhd-8 {
    padding-bottom: 5rem !important; }
  .pl-fhd-8,
  .px-fhd-8 {
    padding-left: 5rem !important; }
  .p-fhd-9 {
    padding: 6rem !important; }
  .pt-fhd-9,
  .py-fhd-9 {
    padding-top: 6rem !important; }
  .pr-fhd-9,
  .px-fhd-9 {
    padding-right: 6rem !important; }
  .pb-fhd-9,
  .py-fhd-9 {
    padding-bottom: 6rem !important; }
  .pl-fhd-9,
  .px-fhd-9 {
    padding-left: 6rem !important; }
  .p-fhd-10 {
    padding: 7rem !important; }
  .pt-fhd-10,
  .py-fhd-10 {
    padding-top: 7rem !important; }
  .pr-fhd-10,
  .px-fhd-10 {
    padding-right: 7rem !important; }
  .pb-fhd-10,
  .py-fhd-10 {
    padding-bottom: 7rem !important; }
  .pl-fhd-10,
  .px-fhd-10 {
    padding-left: 7rem !important; }
  .m-fhd-n1 {
    margin: -0.25rem !important; }
  .mt-fhd-n1,
  .my-fhd-n1 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n1,
  .mx-fhd-n1 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n1,
  .my-fhd-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n1,
  .mx-fhd-n1 {
    margin-left: -0.25rem !important; }
  .m-fhd-n2 {
    margin: -0.25rem !important; }
  .mt-fhd-n2,
  .my-fhd-n2 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n2,
  .mx-fhd-n2 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n2,
  .my-fhd-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n2,
  .mx-fhd-n2 {
    margin-left: -0.25rem !important; }
  .m-fhd-n3 {
    margin: -0.25rem !important; }
  .mt-fhd-n3,
  .my-fhd-n3 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n3,
  .mx-fhd-n3 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n3,
  .my-fhd-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n3,
  .mx-fhd-n3 {
    margin-left: -0.25rem !important; }
  .m-fhd-n4 {
    margin: -0.25rem !important; }
  .mt-fhd-n4,
  .my-fhd-n4 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n4,
  .mx-fhd-n4 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n4,
  .my-fhd-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n4,
  .mx-fhd-n4 {
    margin-left: -0.25rem !important; }
  .m-fhd-n5 {
    margin: -0.25rem !important; }
  .mt-fhd-n5,
  .my-fhd-n5 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n5,
  .mx-fhd-n5 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n5,
  .my-fhd-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n5,
  .mx-fhd-n5 {
    margin-left: -0.25rem !important; }
  .m-fhd-n6 {
    margin: -0.25rem !important; }
  .mt-fhd-n6,
  .my-fhd-n6 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n6,
  .mx-fhd-n6 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n6,
  .my-fhd-n6 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n6,
  .mx-fhd-n6 {
    margin-left: -0.25rem !important; }
  .m-fhd-n7 {
    margin: -0.25rem !important; }
  .mt-fhd-n7,
  .my-fhd-n7 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n7,
  .mx-fhd-n7 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n7,
  .my-fhd-n7 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n7,
  .mx-fhd-n7 {
    margin-left: -0.25rem !important; }
  .m-fhd-n8 {
    margin: -0.25rem !important; }
  .mt-fhd-n8,
  .my-fhd-n8 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n8,
  .mx-fhd-n8 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n8,
  .my-fhd-n8 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n8,
  .mx-fhd-n8 {
    margin-left: -0.25rem !important; }
  .m-fhd-n9 {
    margin: -0.25rem !important; }
  .mt-fhd-n9,
  .my-fhd-n9 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n9,
  .mx-fhd-n9 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n9,
  .my-fhd-n9 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n9,
  .mx-fhd-n9 {
    margin-left: -0.25rem !important; }
  .m-fhd-n10 {
    margin: -0.25rem !important; }
  .mt-fhd-n10,
  .my-fhd-n10 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n10,
  .mx-fhd-n10 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n10,
  .my-fhd-n10 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n10,
  .mx-fhd-n10 {
    margin-left: -0.25rem !important; }
  .m-fhd-auto {
    margin: auto !important; }
  .mt-fhd-auto,
  .my-fhd-auto {
    margin-top: auto !important; }
  .mr-fhd-auto,
  .mx-fhd-auto {
    margin-right: auto !important; }
  .mb-fhd-auto,
  .my-fhd-auto {
    margin-bottom: auto !important; }
  .ml-fhd-auto,
  .mx-fhd-auto {
    margin-left: auto !important; } }

@media (min-width: 2540px) {
  .m-qhd-0 {
    margin: 0 !important; }
  .mt-qhd-0,
  .my-qhd-0 {
    margin-top: 0 !important; }
  .mr-qhd-0,
  .mx-qhd-0 {
    margin-right: 0 !important; }
  .mb-qhd-0,
  .my-qhd-0 {
    margin-bottom: 0 !important; }
  .ml-qhd-0,
  .mx-qhd-0 {
    margin-left: 0 !important; }
  .m-qhd-1 {
    margin: 0.25rem !important; }
  .mt-qhd-1,
  .my-qhd-1 {
    margin-top: 0.25rem !important; }
  .mr-qhd-1,
  .mx-qhd-1 {
    margin-right: 0.25rem !important; }
  .mb-qhd-1,
  .my-qhd-1 {
    margin-bottom: 0.25rem !important; }
  .ml-qhd-1,
  .mx-qhd-1 {
    margin-left: 0.25rem !important; }
  .m-qhd-2 {
    margin: 0.5rem !important; }
  .mt-qhd-2,
  .my-qhd-2 {
    margin-top: 0.5rem !important; }
  .mr-qhd-2,
  .mx-qhd-2 {
    margin-right: 0.5rem !important; }
  .mb-qhd-2,
  .my-qhd-2 {
    margin-bottom: 0.5rem !important; }
  .ml-qhd-2,
  .mx-qhd-2 {
    margin-left: 0.5rem !important; }
  .m-qhd-3 {
    margin: 1rem !important; }
  .mt-qhd-3,
  .my-qhd-3 {
    margin-top: 1rem !important; }
  .mr-qhd-3,
  .mx-qhd-3 {
    margin-right: 1rem !important; }
  .mb-qhd-3,
  .my-qhd-3 {
    margin-bottom: 1rem !important; }
  .ml-qhd-3,
  .mx-qhd-3 {
    margin-left: 1rem !important; }
  .m-qhd-4 {
    margin: 1.5rem !important; }
  .mt-qhd-4,
  .my-qhd-4 {
    margin-top: 1.5rem !important; }
  .mr-qhd-4,
  .mx-qhd-4 {
    margin-right: 1.5rem !important; }
  .mb-qhd-4,
  .my-qhd-4 {
    margin-bottom: 1.5rem !important; }
  .ml-qhd-4,
  .mx-qhd-4 {
    margin-left: 1.5rem !important; }
  .m-qhd-5 {
    margin: 2rem !important; }
  .mt-qhd-5,
  .my-qhd-5 {
    margin-top: 2rem !important; }
  .mr-qhd-5,
  .mx-qhd-5 {
    margin-right: 2rem !important; }
  .mb-qhd-5,
  .my-qhd-5 {
    margin-bottom: 2rem !important; }
  .ml-qhd-5,
  .mx-qhd-5 {
    margin-left: 2rem !important; }
  .m-qhd-6 {
    margin: 3rem !important; }
  .mt-qhd-6,
  .my-qhd-6 {
    margin-top: 3rem !important; }
  .mr-qhd-6,
  .mx-qhd-6 {
    margin-right: 3rem !important; }
  .mb-qhd-6,
  .my-qhd-6 {
    margin-bottom: 3rem !important; }
  .ml-qhd-6,
  .mx-qhd-6 {
    margin-left: 3rem !important; }
  .m-qhd-7 {
    margin: 4rem !important; }
  .mt-qhd-7,
  .my-qhd-7 {
    margin-top: 4rem !important; }
  .mr-qhd-7,
  .mx-qhd-7 {
    margin-right: 4rem !important; }
  .mb-qhd-7,
  .my-qhd-7 {
    margin-bottom: 4rem !important; }
  .ml-qhd-7,
  .mx-qhd-7 {
    margin-left: 4rem !important; }
  .m-qhd-8 {
    margin: 5rem !important; }
  .mt-qhd-8,
  .my-qhd-8 {
    margin-top: 5rem !important; }
  .mr-qhd-8,
  .mx-qhd-8 {
    margin-right: 5rem !important; }
  .mb-qhd-8,
  .my-qhd-8 {
    margin-bottom: 5rem !important; }
  .ml-qhd-8,
  .mx-qhd-8 {
    margin-left: 5rem !important; }
  .m-qhd-9 {
    margin: 6rem !important; }
  .mt-qhd-9,
  .my-qhd-9 {
    margin-top: 6rem !important; }
  .mr-qhd-9,
  .mx-qhd-9 {
    margin-right: 6rem !important; }
  .mb-qhd-9,
  .my-qhd-9 {
    margin-bottom: 6rem !important; }
  .ml-qhd-9,
  .mx-qhd-9 {
    margin-left: 6rem !important; }
  .m-qhd-10 {
    margin: 7rem !important; }
  .mt-qhd-10,
  .my-qhd-10 {
    margin-top: 7rem !important; }
  .mr-qhd-10,
  .mx-qhd-10 {
    margin-right: 7rem !important; }
  .mb-qhd-10,
  .my-qhd-10 {
    margin-bottom: 7rem !important; }
  .ml-qhd-10,
  .mx-qhd-10 {
    margin-left: 7rem !important; }
  .p-qhd-0 {
    padding: 0 !important; }
  .pt-qhd-0,
  .py-qhd-0 {
    padding-top: 0 !important; }
  .pr-qhd-0,
  .px-qhd-0 {
    padding-right: 0 !important; }
  .pb-qhd-0,
  .py-qhd-0 {
    padding-bottom: 0 !important; }
  .pl-qhd-0,
  .px-qhd-0 {
    padding-left: 0 !important; }
  .p-qhd-1 {
    padding: 0.25rem !important; }
  .pt-qhd-1,
  .py-qhd-1 {
    padding-top: 0.25rem !important; }
  .pr-qhd-1,
  .px-qhd-1 {
    padding-right: 0.25rem !important; }
  .pb-qhd-1,
  .py-qhd-1 {
    padding-bottom: 0.25rem !important; }
  .pl-qhd-1,
  .px-qhd-1 {
    padding-left: 0.25rem !important; }
  .p-qhd-2 {
    padding: 0.5rem !important; }
  .pt-qhd-2,
  .py-qhd-2 {
    padding-top: 0.5rem !important; }
  .pr-qhd-2,
  .px-qhd-2 {
    padding-right: 0.5rem !important; }
  .pb-qhd-2,
  .py-qhd-2 {
    padding-bottom: 0.5rem !important; }
  .pl-qhd-2,
  .px-qhd-2 {
    padding-left: 0.5rem !important; }
  .p-qhd-3 {
    padding: 1rem !important; }
  .pt-qhd-3,
  .py-qhd-3 {
    padding-top: 1rem !important; }
  .pr-qhd-3,
  .px-qhd-3 {
    padding-right: 1rem !important; }
  .pb-qhd-3,
  .py-qhd-3 {
    padding-bottom: 1rem !important; }
  .pl-qhd-3,
  .px-qhd-3 {
    padding-left: 1rem !important; }
  .p-qhd-4 {
    padding: 1.5rem !important; }
  .pt-qhd-4,
  .py-qhd-4 {
    padding-top: 1.5rem !important; }
  .pr-qhd-4,
  .px-qhd-4 {
    padding-right: 1.5rem !important; }
  .pb-qhd-4,
  .py-qhd-4 {
    padding-bottom: 1.5rem !important; }
  .pl-qhd-4,
  .px-qhd-4 {
    padding-left: 1.5rem !important; }
  .p-qhd-5 {
    padding: 2rem !important; }
  .pt-qhd-5,
  .py-qhd-5 {
    padding-top: 2rem !important; }
  .pr-qhd-5,
  .px-qhd-5 {
    padding-right: 2rem !important; }
  .pb-qhd-5,
  .py-qhd-5 {
    padding-bottom: 2rem !important; }
  .pl-qhd-5,
  .px-qhd-5 {
    padding-left: 2rem !important; }
  .p-qhd-6 {
    padding: 3rem !important; }
  .pt-qhd-6,
  .py-qhd-6 {
    padding-top: 3rem !important; }
  .pr-qhd-6,
  .px-qhd-6 {
    padding-right: 3rem !important; }
  .pb-qhd-6,
  .py-qhd-6 {
    padding-bottom: 3rem !important; }
  .pl-qhd-6,
  .px-qhd-6 {
    padding-left: 3rem !important; }
  .p-qhd-7 {
    padding: 4rem !important; }
  .pt-qhd-7,
  .py-qhd-7 {
    padding-top: 4rem !important; }
  .pr-qhd-7,
  .px-qhd-7 {
    padding-right: 4rem !important; }
  .pb-qhd-7,
  .py-qhd-7 {
    padding-bottom: 4rem !important; }
  .pl-qhd-7,
  .px-qhd-7 {
    padding-left: 4rem !important; }
  .p-qhd-8 {
    padding: 5rem !important; }
  .pt-qhd-8,
  .py-qhd-8 {
    padding-top: 5rem !important; }
  .pr-qhd-8,
  .px-qhd-8 {
    padding-right: 5rem !important; }
  .pb-qhd-8,
  .py-qhd-8 {
    padding-bottom: 5rem !important; }
  .pl-qhd-8,
  .px-qhd-8 {
    padding-left: 5rem !important; }
  .p-qhd-9 {
    padding: 6rem !important; }
  .pt-qhd-9,
  .py-qhd-9 {
    padding-top: 6rem !important; }
  .pr-qhd-9,
  .px-qhd-9 {
    padding-right: 6rem !important; }
  .pb-qhd-9,
  .py-qhd-9 {
    padding-bottom: 6rem !important; }
  .pl-qhd-9,
  .px-qhd-9 {
    padding-left: 6rem !important; }
  .p-qhd-10 {
    padding: 7rem !important; }
  .pt-qhd-10,
  .py-qhd-10 {
    padding-top: 7rem !important; }
  .pr-qhd-10,
  .px-qhd-10 {
    padding-right: 7rem !important; }
  .pb-qhd-10,
  .py-qhd-10 {
    padding-bottom: 7rem !important; }
  .pl-qhd-10,
  .px-qhd-10 {
    padding-left: 7rem !important; }
  .m-qhd-n1 {
    margin: -0.25rem !important; }
  .mt-qhd-n1,
  .my-qhd-n1 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n1,
  .mx-qhd-n1 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n1,
  .my-qhd-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n1,
  .mx-qhd-n1 {
    margin-left: -0.25rem !important; }
  .m-qhd-n2 {
    margin: -0.25rem !important; }
  .mt-qhd-n2,
  .my-qhd-n2 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n2,
  .mx-qhd-n2 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n2,
  .my-qhd-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n2,
  .mx-qhd-n2 {
    margin-left: -0.25rem !important; }
  .m-qhd-n3 {
    margin: -0.25rem !important; }
  .mt-qhd-n3,
  .my-qhd-n3 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n3,
  .mx-qhd-n3 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n3,
  .my-qhd-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n3,
  .mx-qhd-n3 {
    margin-left: -0.25rem !important; }
  .m-qhd-n4 {
    margin: -0.25rem !important; }
  .mt-qhd-n4,
  .my-qhd-n4 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n4,
  .mx-qhd-n4 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n4,
  .my-qhd-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n4,
  .mx-qhd-n4 {
    margin-left: -0.25rem !important; }
  .m-qhd-n5 {
    margin: -0.25rem !important; }
  .mt-qhd-n5,
  .my-qhd-n5 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n5,
  .mx-qhd-n5 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n5,
  .my-qhd-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n5,
  .mx-qhd-n5 {
    margin-left: -0.25rem !important; }
  .m-qhd-n6 {
    margin: -0.25rem !important; }
  .mt-qhd-n6,
  .my-qhd-n6 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n6,
  .mx-qhd-n6 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n6,
  .my-qhd-n6 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n6,
  .mx-qhd-n6 {
    margin-left: -0.25rem !important; }
  .m-qhd-n7 {
    margin: -0.25rem !important; }
  .mt-qhd-n7,
  .my-qhd-n7 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n7,
  .mx-qhd-n7 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n7,
  .my-qhd-n7 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n7,
  .mx-qhd-n7 {
    margin-left: -0.25rem !important; }
  .m-qhd-n8 {
    margin: -0.25rem !important; }
  .mt-qhd-n8,
  .my-qhd-n8 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n8,
  .mx-qhd-n8 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n8,
  .my-qhd-n8 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n8,
  .mx-qhd-n8 {
    margin-left: -0.25rem !important; }
  .m-qhd-n9 {
    margin: -0.25rem !important; }
  .mt-qhd-n9,
  .my-qhd-n9 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n9,
  .mx-qhd-n9 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n9,
  .my-qhd-n9 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n9,
  .mx-qhd-n9 {
    margin-left: -0.25rem !important; }
  .m-qhd-n10 {
    margin: -0.25rem !important; }
  .mt-qhd-n10,
  .my-qhd-n10 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n10,
  .mx-qhd-n10 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n10,
  .my-qhd-n10 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n10,
  .mx-qhd-n10 {
    margin-left: -0.25rem !important; }
  .m-qhd-auto {
    margin: auto !important; }
  .mt-qhd-auto,
  .my-qhd-auto {
    margin-top: auto !important; }
  .mr-qhd-auto,
  .mx-qhd-auto {
    margin-right: auto !important; }
  .mb-qhd-auto,
  .my-qhd-auto {
    margin-bottom: auto !important; }
  .ml-qhd-auto,
  .mx-qhd-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1900px) {
  .text-fhd-left {
    text-align: left !important; }
  .text-fhd-right {
    text-align: right !important; }
  .text-fhd-center {
    text-align: center !important; } }

@media (min-width: 2540px) {
  .text-qhd-left {
    text-align: left !important; }
  .text-qhd-right {
    text-align: right !important; }
  .text-qhd-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #1f2041 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #06070d !important; }

.text-secondary {
  color: #2cab6c !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #1c6e46 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #fff !important; }

a.text-light:hover, a.text-light:focus {
  color: #d9d9d9 !important; }

.text-dark {
  color: #212121 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-ternary {
  color: #f7fcfe !important; }

a.text-ternary:hover, a.text-ternary:focus {
  color: #b3e3f6 !important; }

.text-body {
  color: #212121 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: rgba(255, 255, 255, 0.15); }
  .table .thead-dark th {
    color: inherit;
    border-color: rgba(255, 255, 255, 0.15); } }

/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.flex {
  display: flex !important; }

.flex-justify-start {
  justify-content: flex-start; }

.flex-justify-center {
  justify-content: center; }

.flex-justify-end {
  justify-content: flex-end; }

.flex--grow {
  flex: 1; }

.flex--shrink {
  flex: 0 1; }

@media screen {
  .js {
    /* So they look staggered */ } }
  @media screen and (min-width: 992px) {
    .js .fromLeft {
      opacity: 0.0;
      transform: translate3d(-128px, 0, 0); }
    .js .fromRight {
      opacity: 0.0;
      transform: translate3d(128px, 0, 0); }
    .js .slideUp {
      opacity: 0.0;
      transform: translate3d(0, 128px, 0); } }

@media screen {
    .js .slide {
      animation: slide;
      animation-delay: 0;
      animation-duration: 500ms;
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-play-state: running; }
  @keyframes slide {
    to {
      opacity: 1.0;
      transform: translate3d(0, 0, 0); } }
  @keyframes wipe {
    to {
      transform: scale3d(0, 1, 1);
      will-change: unset; } }
  @keyframes slide {
    to {
      opacity: 1.0;
      transform: translate3d(0, 0, 0); } }
    .js .fade-in {
      opacity: 0.0; }
  @keyframes fadein {
    from {
      opacity: 0; }
    to {
      opacity: 1; } } }

a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Avenir LT W01_85 Heavy1475544", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1); }

.angle-link {
  font-size: 20px; }
  .angle-link .fas {
    margin-left: 0.25rem;
    position: relative;
    top: 1px; }

h3,
h4,
h5,
h6 {
  font-family: "Avenir LT W01_65 Medium1475532", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal; }

h1.is-blue,
h2.is-blue,
h3.is-blue,
h4.is-blue,
h5.is-blue,
h6.is-blue {
  color: #1f2041; }

p.is-large {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    p.is-large {
      font-size: calc(1.3rem + 0.6vw) ; } }

.intro {
  font-size: 1.25rem;
  font-family: "Avenir LT W01_65 Medium1475532", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal; }

small {
  font-size: 0.875rem;
  text-transform: none; }
  small a {
    text-decoration: none; }

.page-title {
  font-size: 3.75rem;
  color: #1f2041; }
  @media (max-width: 1200px) {
    .page-title {
      font-size: calc(1.5rem + 3vw) ; } }

.note {
  color: #a1a2af; }

.list-unstyled > li {
  white-space: nowrap; }

.banner {
  margin-top: 40px;
  position: relative; }
  @media (min-width: 992px) {
    .banner {
      margin-top: 110px; } }
  .banner::before {
    background-image: url("../img/curve-top.svg");
    background-size: 100% 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    bottom: 0;
    content: '';
    height: 186px;
    left: 0;
    position: absolute;
    transform: scaleY(-1);
    right: 0;
    z-index: 1; }
    @media (max-width: 991px) {
      .banner::before {
        display: none; } }
  .banner ul {
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0; }
  .banner h1,
  .banner h2,
  .banner h3,
  .banner h4,
  .banner h5,
  .banner h6,
  .banner p {
    color: white; }
  .banner p {
    font-size: 20px; }
  .banner .flickity-viewport {
    overflow: hidden;
    transition: all 0.5s; }
    @media (min-width: 1368px) {
      .banner .flickity-viewport {
        padding-bottom: 42.8125%; } }

@media (min-width: 992px) {
  .banner__inner {
    overflow: hidden;
    height: 580px; } }

@media (min-width: 1368px) {
  .banner__inner {
    padding-bottom: 42.8125%; } }

@media (min-width: 1368px) {
  .banner__inner ul {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; } }

.banner__slide {
  background-color: #2cab6c;
  margin-right: 30px;
  width: 100%; }
  .banner__slide picture {
    display: block;
    height: 90vh;
    position: relative; }
    .banner__slide picture.no-image::after {
      display: none; }
    @media (min-width: 992px) {
      .banner__slide picture {
        height: 580px; } }
    @media (min-width: 1368px) {
      .banner__slide picture {
        height: auto;
        padding-bottom: 42.8125%; } }
    .banner__slide picture::after {
      background: linear-gradient(180deg, rgba(69, 70, 97, 0) 0%, #454661 100%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0; }
  .banner__slide img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    width: 100%; }
  .banner__slide .container {
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transition: all 0.5s;
    transform: translateY(-50%); }
    @media (min-width: 992px) {
      .banner__slide .container {
        top: 5.8125vw;
        transform: none; } }

.banner--no-image {
  background-color: #2cab6c;
  padding: 80px 0 60px; }
  @media (min-width: 992px) {
    .banner--no-image {
      padding: 0;
      min-height: 650px; } }
  .banner--no-image h1 {
    color: white; }
  @media (min-width: 992px) {
    .banner--no-image .container {
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transition: all 0.5s;
      transform: translateY(-50%); } }

.banner--secure {
  align-items: center;
  background-color: #e4f5fe;
  display: flex;
  margin-top: 66px;
  margin-bottom: 72px;
  min-height: 300px;
  padding-top: 55px;
  padding-bottom: 55px; }
  .banner--secure h1,
  .banner--secure h2,
  .banner--secure h3,
  .banner--secure h4,
  .banner--secure h5,
  .banner--secure h6,
  .banner--secure p {
    color: #1f2041; }
  .banner--secure h1 {
    font-size: 50px; }
  .banner--secure p {
    font-size: 18px; }
  .banner--secure::before {
    display: none; }
  .banner--secure .banner__caption {
    margin-bottom: 36px; }
  @media (min-width: 992px) {
    .banner--secure .row--banner-jobs .col-lg-4,
    .banner--secure .row--banner-jobs .col-lg-5,
    .banner--secure .row--banner-jobs .col-lg-2 {
      padding-left: 0;
      padding-right: 0; }
      .banner--secure .row--banner-jobs .col-lg-4:first-of-type,
      .banner--secure .row--banner-jobs .col-lg-5:first-of-type,
      .banner--secure .row--banner-jobs .col-lg-2:first-of-type {
        padding-left: 15px; }
        .banner--secure .row--banner-jobs .col-lg-4:first-of-type .Select,
        .banner--secure .row--banner-jobs .col-lg-5:first-of-type .Select,
        .banner--secure .row--banner-jobs .col-lg-2:first-of-type .Select {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px; }
      .banner--secure .row--banner-jobs .col-lg-4:last-of-type,
      .banner--secure .row--banner-jobs .col-lg-5:last-of-type,
      .banner--secure .row--banner-jobs .col-lg-2:last-of-type {
        padding-right: 15px; }
        .banner--secure .row--banner-jobs .col-lg-4:last-of-type .Select,
        .banner--secure .row--banner-jobs .col-lg-5:last-of-type .Select,
        .banner--secure .row--banner-jobs .col-lg-2:last-of-type .Select {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px; } }
  .banner--secure + .FilterBar {
    margin-top: -72px; }

.breadcrumb {
  margin-bottom: 1rem;
  margin-top: 1.5rem; }

.breadcrumb-item {
  font-family: "Avenir LT W01_65 Medium1475532", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 550; }
  .breadcrumb-item:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400; }
  .breadcrumb-item a {
    color: #a1a2af;
    font-family: inherit; }

.btn, #login .button.button-primary {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2cab6c;
  color: white;
  max-width: 100%;
  white-space: nowrap;
  transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out; }

.btn-light {
  color: #1f2041; }

.btn-wide {
  padding-left: 4rem;
  padding-right: 4rem; }

.btn-transparent {
  background-color: transparent;
  border-color: #2cab6c;
  color: #2cab6c;
  font-weight: 600; }
  .btn-transparent:hover, .btn-transparent:focus, .btn-transparent:active {
    background: #1f2041;
    border-color: #1f2041;
    color: #fff; }

.btn-primary:hover {
  background: #fff;
  border-color: #1f2041;
  color: #1f2041; }

.btn-secondary:hover, #login .button.button-primary:hover, .btn-secondary:focus, #login .button.button-primary:focus, .btn-secondary:active, #login .button.button-primary:active {
  background: #1f2041;
  border-color: #1f2041;
  color: #fff; }

.btn--ultra {
  height: 100%;
  width: 100%; }

.btn-link {
  font-size: 20px;
  background-color: transparent;
  border: 0;
  font-family: 'Avenir';
  font-weight: bold;
  text-transform: none; }

a.dropdown-toggle {
  -webkit-appearance: unset !important; }

.circle-block {
  margin-bottom: 50px; }
  @media (min-width: 768px) {
    .circle-block {
      margin-bottom: 0; } }

.circle {
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 516px;
  position: relative;
  z-index: 4; }
  @media (min-width: 768px) {
    .circle {
      margin-bottom: 138px; } }

.circle__inner {
  background-color: #e4f5fe;
  border-radius: 30px;
  padding: 30px;
  position: relative; }
  @media (min-width: 768px) {
    .circle__inner {
      border-radius: 50%;
      padding: 0;
      padding-bottom: 100%; } }

.circle__text {
  text-align: center;
  width: 100%; }
  @media (min-width: 768px) {
    .circle__text {
      position: absolute;
      left: 50%;
      max-width: 353px;
      top: 50%;
      transform: translateY(-50%) translateX(-50%) scale(0.75); } }
  @media (min-width: 992px) {
    .circle__text {
      max-width: 353px;
      transform: translateY(-50%) translateX(-50%); } }
  .circle__text h3 {
    color: #1f2041; }
  .circle__text p {
    font-size: 1.25rem;
    color: #1f2041;
    font-family: "Avenir LT W01_45 Book1475508", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 350; }

@media (min-height: 812px), (max-width: 992px) {
  .banner + .circle-block {
    margin-top: -60px; } }

@media (min-width: 992px) {
  .banner + .circle-block {
    margin-top: -180px; } }

.content a:not(.btn) {
  color: #2cab6c;
  text-decoration: none; }
  .content a:not(.btn):hover {
    color: #1f2041; }

.content p {
  font-size: 1.125rem;
  font-family: "Avenir LT W01_45 Book1475508", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 350; }

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: #2cab6c;
  font-family: "Avenir LT W01_65 Medium1475532", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 550; }

.content li {
  font-size: 1.125rem;
  color: #1f2041;
  font-family: "Avenir LT W01_65 Medium1475532", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 550; }

.content blockquote {
  display: block;
  padding-bottom: 4rem;
  padding-left: 64px !important;
  padding-right: 0 !important;
  padding-top: 4rem;
  position: relative;
  z-index: 1; }
  .content blockquote:before {
    background: #f7fcfe;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    bottom: 0;
    height: 100%;
    width: 150vw;
    z-index: 0; }
  .content blockquote > p:first-child {
    font-size: 2.875rem;
    color: #1f2041;
    font-family: fieldwork, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 3rem;
    position: relative;
    text-align: left;
    z-index: 1; }
    @media (max-width: 1200px) {
      .content blockquote > p:first-child {
        font-size: calc(1.4125rem + 1.95vw) ; } }
    .content blockquote > p:first-child:before {
      color: #1f2041;
      content: "\201C";
      display: block;
      font-size: 150px;
      font-weight: 300;
      left: -4.5rem;
      opacity: 0.5;
      position: absolute;
      top: 2.5rem;
      z-index: 1; }
    .content blockquote > p:first-child:after {
      bottom: 1.75rem;
      color: #1f2041;
      content: "\201D";
      display: inline-block;
      font-size: 150px;
      font-weight: 300;
      left: 1rem;
      line-height: 0;
      opacity: 0.5;
      position: relative;
      transform: rotate(180deg);
      z-index: 1; }
  .content blockquote > p:last-child {
    font-size: 18px;
    color: #1f2041;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 2rem;
    position: relative;
    z-index: 1; }
  .content blockquote strong, .content blockquote b {
    font-weight: 700; }
  @media (min-width: 1900px) {
    .content blockquote {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.content .two-cols {
  column-count: 2;
  column-gap: 4em;
  column-width: 360px;
  margin-bottom: 2rem; }
  .content .two-cols > p:last-child {
    margin-bottom: 0; }

.content ul:not(.list-unstyled) {
  list-style: none;
  margin-bottom: 2.5rem;
  padding-left: 2rem; }
  .content ul:not(.list-unstyled) li {
    margin-bottom: 1rem; }
    .content ul:not(.list-unstyled) li::before {
      content: "•";
      color: #1f2041;
      display: inline-block;
      margin-left: -2rem;
      opacity: 0.4;
      width: 2rem; }

.content ol {
  counter-reset: li;
  /* Initiate a counter */
  margin-bottom: 1.75rem;
  margin-left: 0;
  padding-left: 1.25em; }

.content ol > li {
  display: block;
  float: none;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  text-indent: none; }

.content ol > li:before {
  box-sizing: border-box;
  color: #1f2041;
  content: counter(li, decimal) ". ";
  /* Use the counter as content */
  counter-increment: li;
  /* Increment the counter by 1 */
  /* Position and style the number */
  position: absolute;
  top: 0;
  left: -1.25em;
  /* Some space between the number and the content in browsers that support
            generated content but not positioning it (Camino 2 is one example) */
  line-height: 1.5;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 1em; }

.content ol > li > p {
  margin: 0; }

@media (max-width: 767.98px) {
  .content a {
    word-break: break-word; } }

.content img {
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 100%;
  height: auto; }

.content .alignright,
.content .alignleft,
.content .aligncenter,
.content .alignnone {
  margin: 2rem auto;
  max-width: 100%; }
  @media (max-width: 767.98px) {
    .content .alignright,
    .content .alignleft,
    .content .aligncenter,
    .content .alignnone {
      display: block;
      text-align: center; } }

@media (max-width: 991.98px) {
  .content .alignleft {
    clear: both; } }

@media (min-width: 768px) {
  .content .alignleft {
    float: left;
    margin: 1rem;
    margin-left: 0; } }

@media (max-width: 991.98px) {
  .content .alignright {
    clear: both; } }

@media (min-width: 768px) {
  .content .alignright {
    float: right;
    margin: 1rem;
    margin-right: 0; } }

@media (min-width: 768px) {
  .content .aligncenter {
    margin: 3rem auto;
    display: block; } }

@media (min-width: 768px) {
  .content .alignnone + img {
    margin-left: 1rem; } }

.content figure .wp-caption-text {
  font-size: 0.85rem;
  color: #1f2041; }

.cta p {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    .cta p {
      font-size: calc(1.3rem + 0.6vw) ; } }

.mini-cta {
  background: #f7fcfe;
  outline: 1px solid hotpink; }
  .mini-cta i {
    font-size: 1.5rem; }
    @media (max-width: 1200px) {
      .mini-cta i {
        font-size: calc(1.275rem + 0.3vw) ; } }
  .mini-cta h6 {
    font-family: "Avenir LT W01_85 Heavy1475544", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  .mini-cta p {
    font-size: 1.125rem;
    color: #747588;
    font-family: "Avenir LT W01_65 Medium1475532"; }

.curve-bottom,
.curve-top {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  max-height: 100%;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 3; }

.curve-top {
  bottom: unset;
  display: block;
  height: auto;
  left: 0;
  max-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleY(-1);
  width: 100%; }

.curvy-top {
  position: relative;
  transform: translateY(-100%);
  z-index: 2; }
  .curvy-top::after {
    background-image: url("../img/curve_bottom.svg");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    content: "";
    display: block;
    padding-bottom: 9.75%;
    position: absolute;
    width: 100%; }

.footer {
  background: #fff;
  border-top: 1px solid #e8e9f3;
  color: #1f2041;
  flex-shrink: 0; }
  @media (max-width: 991px) {
    .footer .nav-item {
      width: 100%; } }
  .footer .nav-item .nav-link {
    font-size: 1.1875rem;
    color: #1f2041;
    font-family: "Avenir LT W01_85 Heavy1475544", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: normal;
    padding-left: 5px;
    padding-right: 5px;
    text-decoration: none; }
    .footer .nav-item .nav-link:hover {
      color: #2cab6c; }

.footer-legal {
  flex-shrink: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative; }
  .footer-legal p {
    color: #1f2041;
    font-family: 'Avenir';
    font-size: 15px;
    font-weight: normal; }
  .footer-legal:before {
    background-color: #f5f5f5;
    bottom: 0;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100vw; }
  .footer-legal a {
    color: #1f2041;
    display: block;
    font-family: 'Avenir';
    font-size: 15px;
    font-weight: normal;
    padding: 0; }
  .footer-legal .nav-item {
    display: flex;
    position: relative;
    text-transform: uppercase; }
    .footer-legal .nav-item::after {
      content: '|';
      margin: -2px 8px 0 8px; }
    .footer-legal .nav-item:nth-last-child(1)::after {
      display: none; }
    .footer-legal .nav-item a:hover {
      color: #2cab6c; }
    .footer-legal .nav-item a:focus {
      text-decoration: underline; }

.footer--dashboard {
  margin-top: 55px; }

.footer-legal--dashboard {
  margin-bottom: 24px; }
  .footer-legal--dashboard::before {
    display: none; }
  .footer-legal--dashboard a {
    font-size: 14px; }
  .footer-legal--dashboard p {
    font-size: 13px; }

#login .login-username,
#login .login-password {
  margin-left: 0 !important;
  margin-right: 0 !important; }

#login #user_login,
#login #user_pass {
  margin-bottom: 0 !important; }

#login .login-remember,
#login .forgot-password {
  display: none; }

.login__form-container {
  margin-bottom: 2rem; }
  @media (max-width: 991px) {
    .login__form-container {
      padding-bottom: 2rem;
      border-bottom: 1px solid #e8e9f3; } }

#register {
  margin-bottom: 88px; }

.form-section-heading {
  border-bottom: 1px solid #e8e9f3;
  color: #2cab6c; }

label {
  font-family: "Avenir LT W01_45 Book1475508", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 0.25em; }

.custom-control-label {
  color: #454661;
  padding-left: 0.5rem; }

.custom-select {
  cursor: pointer; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.is-error .custom-control-label {
  background: #fff;
  padding: 0.5rem; }

.is-error input,
.is-error textarea,
.is-error select {
  border: 2px solid #ff424e !important;
  color: #ff424e !important;
  outline: 1px solid #ff424e !important; }

.is-error input[type='checkbox'] + label:before,
.is-error input[type='radio'] + label:before {
  border-color: #ff424e; }

.is-error .input-message {
  font-size: 0.875rem;
  color: #ff424e;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  position: relative;
  visibility: visible;
  width: 100%;
  z-index: 1; }

.alert {
  padding: 35px; }
  .alert p {
    font-size: 18px;
    margin-bottom: 0; }
    .alert p:only-of-type {
      margin-bottom: 0; }

.alert--danger {
  border: 3px solid #ff424e;
  color: #454661; }
  .alert--danger a {
    color: #ff424e; }

.alert--success {
  background: #f4fbf7;
  border: 3px solid #9cde9f;
  color: #454661; }
  .alert--success p {
    font-size: 18px; }
    .alert--success p:only-of-type {
      margin-bottom: 0; }

.alert--dashboard {
  display: flex; }
  .alert--dashboard i {
    color: #2cab6c;
    font-size: 24px;
    margin-right: 30px;
    margin-top: 4px; }

.submit-btn.waiting {
  display: none; }

.gp_form.waiting .submit-btn {
  display: none; }

.gp_form.waiting .spinner,
.gp_form.waiting .spinner-border {
  display: inline-block; }

label small {
  display: inline-block;
  margin-top: 0.5rem; }

.city-town-row {
  display: none; }

.form-control, #login #user_login,
#login #user_pass {
  margin-bottom: 15px;
  box-shadow: none;
  height: calc(1.5em + 1.5rem + 4px); }

.was-validated input[type="checkbox"]:invalid + label, .was-validated input[type="checkbox"]:invalid ~ div {
  color: #dc3545;
  border-color: #dc3545; }

.section {
  border-bottom: 1px solid #e8e9f3;
  margin-bottom: 25px; }
  .section h3 {
    font-size: 24px;
    margin-bottom: 16px;
    margin-top: 0; }

.form-control-container {
  display: flex; }
  .form-control-container > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
    .form-control-container > div > .form-control, #login .form-control-container > div > #user_login,
    #login .form-control-container > div > #user_pass {
      margin-top: auto; }
    .form-control-container > div > .form-control--confirm_password {
      margin-top: 0; }

select.form-control, #login select#user_login,
#login select#user_pass {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/app/themes/gpcswr/src/img/select.png);
  background-repeat: no-repeat;
  background-position: right 15px center; }

.header {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1f2041;
  position: relative;
  transform: translate3d(0, -100%, 0);
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
  width: 100%; }
  .header.float {
    position: fixed;
    z-index: 1030; }
  .header.show {
    transform: translate3d(0, 0, 0); }
  .header .cswr-logo {
    width: 30px;
    height: 50px; }
    @media (min-width: 992px) {
      .header .cswr-logo {
        width: 60px;
        height: 43px; } }

.header__inner {
  display: flex;
  align-items: center;
  margin-bottom: auto;
  margin-top: auto;
  height: 110px;
  max-height: 50px;
  width: 100%;
  transition: all 0.5s; }
  @media (min-width: 992px) {
    .header__inner {
      max-height: 110px; } }
  @media (max-width: 1199.98px) {
    .header__inner > .container {
      max-width: none; } }

.header.is-hidden .header__inner {
  max-height: 50px; }
  @media (min-width: 768px) {
    .header.is-hidden .header__inner {
      max-height: 43px; } }

.header.is-hidden .cswr-logo {
  width: 30px; }

@media (min-width: 768px) {
  .header.is-hidden .logo-text-group,
  .header.is-hidden .logo-text-site {
    opacity: 0; }
  .header.is-hidden .header-nav {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

.menu-button-container {
  position: absolute;
  right: 15px;
  transform: rotate(180deg); }
  .menu-button-container .sidebar__menu-toggle.menu-button {
    transform: translateY(-100%); }

.login-menu-button {
  font-size: 20px;
  position: absolute;
  right: 70px; }
  .login-menu-button a {
    color: white; }
  @media screen and (max-width: 340px) {
    .login-menu-button {
      display: none !important; } }

.page-template-contact .header.is-not-top {
  box-shadow: 0px 1px 0px 0px white; }

img.object-fit-cover {
  object-fit: cover;
  font-family: 'object-fit: cover;'; }

.cswr-logo {
  height: auto;
  margin-right: 1rem;
  transition: all 0.5s;
  width: 60px; }

.header .logo-text-group {
  font-size: 15px; }

.header .logo-text-site {
  font-size: 16px; }
  @media (min-width: 992px) {
    .header .logo-text-site {
      font-size: 20px; } }

.footer .logo-text-group {
  font-size: 14px; }

.footer .logo-text-site {
  font-size: 19px; }

.logo-text-group {
  color: #fff;
  font-family: "Avenir LT W01_65 Medium1475532", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 550;
  line-height: normal;
  transition: all 0.5s; }

.logo-text-site {
  color: #2cab6c;
  font-family: "Avenir LT W01_85 Heavy1475544", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: normal;
  transition: all 0.5s; }

.left-icons-cta .media .far,
.left-icons-cta .media .fas,
.left-icons-cta .media .fal,
.right-icons-cta .media .far,
.right-icons-cta .media .fas,
.right-icons-cta .media .fal {
  font-size: 2.625rem;
  color: #454661; }
  @media (max-width: 1200px) {
    .left-icons-cta .media .far,
    .left-icons-cta .media .fas,
    .left-icons-cta .media .fal,
    .right-icons-cta .media .far,
    .right-icons-cta .media .fas,
    .right-icons-cta .media .fal {
      font-size: calc(1.3875rem + 1.65vw) ; } }

.left-icons-cta .media h5,
.right-icons-cta .media h5 {
  color: #2cab6c;
  font-family: "Avenir LT W01_65 Medium1475532", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 550; }

.left-icons-cta .media p,
.right-icons-cta .media p {
  font-size: 1.125rem; }

.login-reg-link {
  color: #2cab6c !important;
  font-family: "Avenir LT W01_85 Heavy1475544", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 18px; }
  .login-reg-link .fas {
    font-size: 20px;
    margin-right: 0.5rem;
    position: relative;
    top: 2px; }

.footer .login-reg-link {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 19px; }
  .footer .login-reg-link:hover {
    color: #1f2041 !important; }
  .footer .login-reg-link .fas {
    font-size: 24px; }

.header-nav {
  background-color: #1f2041;
  display: flex;
  height: calc(100vh - 40px);
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 100%;
  transform: translateX(100%);
  transition: all 0.5s;
  width: 100vw; }
  @media (min-width: 576px) {
    .header-nav {
      width: 400px; } }
  @media (min-width: 992px) {
    .header-nav {
      background-color: transparent;
      height: auto;
      position: relative;
      right: auto;
      top: auto;
      transition: none;
      transform: none;
      width: auto; } }
  .header-nav.is-open {
    transform: translateX(0); }
  .header-nav .nav {
    flex-direction: column;
    margin-top: 1rem;
    width: 100%; }
    @media (min-width: 992px) {
      .header-nav .nav {
        flex-direction: row;
        margin-top: 0; } }
  .header-nav .nav-item {
    background-color: #1f2041;
    border-radius: 5px;
    color: #fff;
    margin-left: 2px;
    margin-right: 2px;
    position: relative;
    transition: background-color 300ms cubic-bezier(0, 0, 0.2, 1), color 100ms cubic-bezier(0, 0, 0.2, 1); }
    @media (min-width: 992px) {
      .header-nav .nav-item {
        text-align-last: left; } }
    .header-nav .nav-item:hover, .header-nav .nav-item:focus, .header-nav .nav-item:active {
      color: #2cab6c; }
    .header-nav .nav-item i {
      margin-right: 5px; }
    @media (max-width: 991.98px) {
      .header-nav .nav-item i {
        order: 1;
        margin-right: 0;
        margin-left: 5px; } }
    .header-nav .nav-item .nav-link {
      font-size: 20px;
      color: inherit;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Avenir LT W01_85 Heavy1475544", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      text-decoration: none;
      width: 100%; }
      @media (min-width: 992px) {
        .header-nav .nav-item .nav-link {
          font-size: 18px; } }
      .header-nav .nav-item .nav-link.active {
        color: #9fbfea; }
      .header-nav .nav-item .nav-link:hover, .header-nav .nav-item .nav-link:focus, .header-nav .nav-item .nav-link:active {
        color: #2cab6c; }
  .header-nav .login-reg-link {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

.register {
  background: LightBlue; }

.spinner {
  animation: rotation 1.25s infinite linear;
  display: none;
  height: 48px;
  margin: 0;
  max-width: 100%;
  width: 48px; }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

table {
  margin-top: 1rem;
  margin-bottom: 2rem;
  max-width: 100%;
  overflow: auto; }
  table a {
    word-break: break-word; }
  @media (max-width: 767.98px) {
    table {
      display: block; }
      table td, table th {
        padding: 0.25rem; } }

th {
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7fcfe;
  color: #1f2041;
  font-family: "Avenir LT W01_85 Heavy1475544", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1;
  padding: 0.5rem; }

td {
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #454661;
  font-weight: 300;
  padding: 0.5rem; }

tr:nth-child(even) td {
  background-color: #f7fcfe; }

.sidebar {
  background: #1f2041;
  height: 100vh;
  flex-shrink: 0;
  max-width: 45px;
  padding: 21px 21px 30px 4px;
  position: fixed;
  top: 0;
  transition: all 0.3s;
  transform: translateX(-45px);
  z-index: 2; }
  .sidebar.is-open {
    max-width: 100vw;
    padding: 21px 21px 30px 46px;
    transform: translateX(0); }
    .sidebar.is-open .logo-text-group,
    .sidebar.is-open .logo-text-site,
    .sidebar.is-open li {
      opacity: 1;
      transform: translateX(0); }
    @media (min-width: 768px) and (max-width: 1600px) {
      .sidebar.is-open {
        width: 500px;
        max-width: 500px; } }
    @media (min-width: 1600px) {
      .sidebar.is-open {
        max-width: 315px; } }
  @media (min-width: 1600px) {
    .sidebar {
      max-width: 315px;
      padding: 21px 21px 30px 46px;
      position: sticky;
      top: 0;
      transform: translateX(0); } }
  .sidebar .logo-text-group {
    font-size: 15px;
    opacity: 0;
    white-space: nowrap;
    transition: all 0.3s; }
    @media (min-width: 1600px) {
      .sidebar .logo-text-group {
        display: block;
        opacity: 1;
        transform: translateX(0); } }
  .sidebar .logo-text-site {
    font-size: 18px;
    opacity: 0;
    white-space: nowrap;
    transition: all 0.3s; }
    @media (min-width: 1600px) {
      .sidebar .logo-text-site {
        display: block;
        opacity: 1;
        transform: translateX(0); } }
  .sidebar a {
    white-space: nowrap; }

.sidebar__logo {
  display: flex;
  margin-bottom: 53px;
  margin-top: 30px; }
  @media (min-width: 1600px) {
    .sidebar__logo {
      margin-top: 0; } }
  .sidebar__logo img {
    max-width: none;
    width: 40px; }

.sidebar__nav {
  pointer-events: none; }
  .sidebar__nav li {
    opacity: 0;
    transition: all 0.3s;
    width: 100%; }
    @media (min-width: 1600px) {
      .sidebar__nav li {
        opacity: 1; } }
  .sidebar__nav a {
    color: white; }
    .sidebar__nav a i {
      width: 25px;
      font-size: 20px;
      margin-right: 29px;
      transition: all 0.3s; }
    .sidebar__nav a:hover i {
      color: #2cab6c;
      margin-right: 35px;
      transform: scale(1.15); }
  .sidebar__nav .nav-link {
    padding: 1rem 1rem 1rem 46px;
    margin-left: -46px;
    margin-right: -21px; }
    .sidebar__nav .nav-link.active {
      background-color: #454661; }
  .sidebar__nav .return-link {
    color: #2cab6c; }
  .is-open .sidebar__nav {
    pointer-events: auto; }
  @media (min-width: 1600px) {
    .sidebar__nav {
      pointer-events: auto; } }

.sidebar__menu-toggle {
  background-color: #1f2041;
  border: 0;
  height: 30px;
  left: 0;
  margin-left: 7px;
  position: absolute;
  padding: 0;
  top: 15px;
  transform: translateX(36px);
  width: 36px; }
  @media (min-width: 1600px) {
    .sidebar__menu-toggle {
      display: none; } }
  .sidebar__menu-toggle.menu-button {
    margin-bottom: auto;
    margin-top: auto;
    transform: none; }

.sidebar__menu-line {
  background-color: white;
  display: block;
  height: 2px;
  position: absolute;
  left: 7px;
  right: 7px;
  transition: all 0.3s;
  transform-origin: left; }
  .sidebar__menu-line:nth-child(2) {
    top: 7px; }
    .is-open .sidebar__menu-line:nth-child(2) {
      transform: translateY(7px) rotate(-35deg) skewX(-35deg) scaleX(0.65); }
  .sidebar__menu-line:nth-child(3) {
    top: 50%;
    transform: translateY(-50%); }
  .sidebar__menu-line:nth-child(4) {
    bottom: 7px; }
    .is-open .sidebar__menu-line:nth-child(4) {
      transform: translateY(-7px) rotate(35deg) skewX(35deg) scaleX(0.65); }

.sidebar .sidebar__menu-toggle {
  height: 66px;
  top: 0;
  width: 66px; }
  .is-open .sidebar .sidebar__menu-toggle {
    background-color: transparent; }

.sidebar.is-open .sidebar__menu-toggle {
  background-color: transparent;
  transition: all 0.3s;
  transform: translateX(20px); }

.sidebar .sidebar__menu-line {
  left: calc(50% - 11px);
  width: 22px; }
  .sidebar .sidebar__menu-line:nth-child(2) {
    top: 25px; }
  .sidebar .sidebar__menu-line:nth-child(4) {
    bottom: 25px; }

.text-block {
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .text-block {
      margin-bottom: 70px; } }

.feature-block {
  margin-bottom: 60px;
  position: relative;
  z-index: 1; }
  @media (min-width: 768px) {
    .feature-block {
      margin-bottom: 122px; } }
  @media (min-width: 992px) {
    .feature-block:nth-child(2n) .row > .col-lg-5:nth-child(1) {
      order: 2; }
    .feature-block:nth-child(2n) picture {
      padding-bottom: 105.16252%;
      max-width: 523px; } }
  .feature-block picture,
  .feature-block img {
    display: none; }
    @media (min-width: 768px) {
      .feature-block picture,
      .feature-block img {
        display: block; } }
  .feature-block picture {
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    padding-bottom: 117.02128%;
    z-index: 1; }
  .feature-block img {
    position: absolute;
    width: 100%; }
  .feature-block .far {
    color: #454661;
    font-size: 42px; }
  .feature-block h1,
  .feature-block h2,
  .feature-block h3,
  .feature-block h4,
  .feature-block h5,
  .feature-block h6 {
    margin-bottom: 47px; }
  .feature-block h1,
  .feature-block h2,
  .feature-block h3,
  .feature-block h4,
  .feature-block h5,
  .feature-block h6 {
    color: #1f2041;
    font-size: 38px; }
  .feature-block h4 {
    font-size: 20px;
    margin-bottom: 7.5px; }
  .feature-block .btn-link {
    display: inline-block;
    margin-top: 0; }
    @media (min-width: 768px) {
      .feature-block .btn-link {
        margin-top: 50px; } }
    .feature-block .btn-link i {
      font-size: 18px; }

.feature-block__items .row, .split-block__items .row {
  margin-bottom: 16.5px; }

.feature-block__items h1, .split-block__items h1,
.feature-block__items h2,
.split-block__items h2,
.feature-block__items h3,
.split-block__items h3,
.feature-block__items h4,
.split-block__items h4,
.feature-block__items h5,
.split-block__items h5,
.feature-block__items h6,
.split-block__items h6 {
  color: #2cab6c;
  font-size: 20px;
  margin-bottom: 7.5px; }

.feature-blocks {
  background-color: #f7fcfe;
  position: relative;
  padding-top: 35px; }
  .feature-blocks::before, .feature-blocks::after {
    background-image: url("../img/curve-top.svg");
    background-size: 100% 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    content: '';
    height: 170px;
    left: 0;
    position: absolute;
    right: 0; }
    @media (max-width: 991px) {
      .feature-blocks::before, .feature-blocks::after {
        display: none; } }
  .feature-blocks::before {
    top: 0; }
  .feature-blocks::after {
    bottom: 0;
    transform: scaleY(-1);
    z-index: 0; }

.text-block + .feature-blocks {
  margin-bottom: 50px; }
  @media (min-width: 768px) {
    .text-block + .feature-blocks {
      margin-bottom: 176px; } }

.split-block {
  margin-bottom: 60px; }
  .split-block h1,
  .split-block h2,
  .split-block h3,
  .split-block h4,
  .split-block h5,
  .split-block h6 {
    margin-bottom: 47px; }
  .split-block h3 {
    color: #1f2041;
    font-size: 38px; }
  .split-block .far {
    color: #454661;
    font-size: 42px; }

.split-block__items h4 {
  font-size: 20px;
  margin-bottom: 7.5px; }

.small-title {
  color: #1f2041;
  font-family: 'Avenir';
  font-size: 20px;
  font-weight: 500; }

.small-title--with-icon i {
  margin: 0 20px 0 0; }

.cta-block {
  margin-bottom: 73px; }

.cta-block__inner {
  border-top: 1px solid #e8e9f3;
  padding-top: 58px;
  text-align: center; }
  .cta-block__inner h2 {
    font-size: 2.375rem;
    color: #1f2041;
    margin-bottom: 42px; }
    @media (max-width: 1200px) {
      .cta-block__inner h2 {
        font-size: calc(1.3625rem + 1.35vw) ; } }

.phone-numbers {
  display: flex;
  flex-direction: column; }
  .phone-numbers h3 {
    color: #1f2041;
    font-size: 28px; }

.phone-number {
  background-color: #f7fcfe;
  padding: 40px 58px 30px;
  margin-bottom: 33px; }
  .phone-number i {
    color: #1f2041;
    font-size: 24px; }
  .phone-number h4,
  .phone-number a,
  .phone-number p {
    width: 100%; }
  .phone-number h4 {
    color: #1f2041;
    display: inline-block;
    font-size: 24px;
    margin-bottom: 11px; }
  .phone-number a {
    color: #2cab6c;
    display: inline-block;
    font-size: 20px;
    margin-bottom: 16px; }
  .phone-number p {
    color: #747588;
    font-size: 18px; }

.offices {
  background-color: #1f2041;
  color: white;
  margin-bottom: 83px;
  padding-bottom: 73px;
  padding-top: 65px; }
  .offices h2,
  .offices h3 {
    color: inherit; }
  .offices h2 {
    font-size: 38px;
    margin-bottom: 41px; }
  .offices h3 {
    border-bottom: 1px solid #e8e9f3;
    font-size: 20px;
    margin-bottom: 23px;
    text-transform: uppercase;
    padding-bottom: 11px; }
  .offices p {
    font-size: 20px; }

.office {
  margin-bottom: 33px; }

.map {
  padding-bottom: 100%;
  position: relative; }
  @media (min-width: 992px) {
    .map {
      bottom: -73px;
      left: 0;
      width: 50vw;
      position: absolute;
      padding-bottom: 0;
      top: -65px; } }
  .map #map {
    display: block;
    width: 100%;
    height: 100%; }
    @media (max-width: 1199.98px) {
      .map #map {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; } }

.link-block {
  align-items: center;
  background-color: #e4f5fe;
  border-radius: 5px;
  color: #1f2041;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 21px;
  padding: 40px;
  transition: all 0.3s; }
  @media (min-width: 768px) {
    .link-block {
      padding: 80px 40px; } }
  @media (max-width: 991px) {
    .link-block {
      padding: 40px 30px; } }
  @media (max-width: 767px) {
    .link-block {
      flex-direction: row; } }
  .link-block:hover {
    background-color: #2cab6c;
    color: white; }
    .link-block:hover i,
    .link-block:hover span {
      color: white; }
  .link-block i {
    font-size: 52px;
    margin-bottom: 18px;
    transition: inherit; }
    @media (max-width: 767px) {
      .link-block i {
        font-size: 34px;
        margin-right: 30px;
        margin-bottom: 0; } }
  .link-block span {
    font-size: 24px;
    transition: inherit; }
    @media (max-width: 575px) {
      .link-block span {
        font-size: 20px; } }

html {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  scroll-behavior: smooth; }
  @media (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto; } }

body {
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  top: 0 !important; }
  body.page-template-dashboard, body.page-template-jobs, body.page-template-training, body.page-template-user-matrix, body.page-template-faqs, body.page-template-resources, body.single-jobs, body.single-training {
    overflow-x: initial; }

.head-space {
  padding-top: 60px; }
  @media (min-width: 1200px) {
    .head-space {
      padding-top: 110px; } }

.content-wrapper {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 160px; }

.no-scroll {
  overflow: hidden !important;
  overflow-x: none !important; }

.no-focus-outline a:focus,
.no-focus-outline button:focus {
  outline: none; }

#app {
  display: flex; }
  #app #main {
    padding-left: 0;
    width: 100%; }
    @media (min-width: 1600px) {
      #app #main {
        padding-left: 0; } }

.cookie-notice {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); }
  .cookie-notice h2 {
    margin-top: 0;
    font-size: 22px;
    font-weight: 600; }

.cookie-notice__yes-button,
.cookie-notice__no-button {
  /* &:extend(.btn-small); */ }

.cookie-notice__inner {
  padding: 50px 0; }
