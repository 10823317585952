.cswr-logo {
  height: auto;
  margin-right: 1rem;
  transition: all 0.5s;
  width: 60px;
}

.header {
  .logo-text-group {
    @include static-font-size(15px);
  }

  .logo-text-site {
    @include static-font-size(16px);

    @include media-breakpoint-up(lg) {
      @include static-font-size(20px);
    }
  }
}

.footer {
  .logo-text-group {
    @include static-font-size(14px);
  }

  .logo-text-site {
    @include static-font-size(19px);
  }
}

.logo-text-group {
  color: #fff;
  font-family: 'Avenir LT W01_65 Medium1475532', $font-family-sans-serif;
  font-weight: 550;
  line-height: normal;
  transition: all 0.5s;
}

.logo-text-site {
  color: $secondary;
  font-family: 'Avenir LT W01_85 Heavy1475544', $font-family-sans-serif;
  font-weight: 700;
  line-height: normal;
  transition: all 0.5s;
}
