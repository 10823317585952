.curve-bottom,
.curve-top {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  max-height: 100%;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 3;
}

.curve-top {
  bottom: unset;
  display: block;
  height: auto;
  left: 0;
  max-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleY(-1);
  width: 100%;
}

.curvy-top {
  position: relative;
  transform: translateY(-100%);
  z-index: 2;

  &::after {
    background-image: url('../img/curve_bottom.svg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    content: "";
    display: block;
    //height: 187px; // 1600w = 156px, 1920w = 187px
    padding-bottom: (156 / (1600 / 100)) * 1%;
    position: absolute;
    width: 100%;
    //z-index: 2;
  }
}
