// Copy some of these styles to /css/editor_styles.css (for TinyMCE)
.content {
    a:not(.btn) {
      color: $secondary;
      text-decoration: none;

      &:hover {
        color: $primary;
        // text-decoration: underline;
      }
    }

    p {
      @include font-size(18px);
      font-family: "Avenir LT W01_45 Book1475508", $font-family-sans-serif;
      font-weight: 350;
    }

    h1,h2,h3,h4,h5,h6 {
        // @include font-smooth;
        color: $secondary;
        font-family: "Avenir LT W01_65 Medium1475532", $font-family-sans-serif;
        font-weight: 550;
    }

    li {
      @include font-size(18px);
      color: $primary;
      font-family: "Avenir LT W01_65 Medium1475532", $font-family-sans-serif;
      font-weight: 550;
    }

    // p {
    //   margin-bottom: 1.5rem;
    // }

    blockquote {
      display: block;
      padding-bottom: 4rem;
      padding-left: 64px !important;
      padding-right: 0 !important;
      padding-top: 4rem;
      position: relative;
      z-index: 1;

      &:before {
        background: $off-white;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: -100%;
        // right: 0;
        bottom: 0;
        height: 100%;
        width: 150vw;
        z-index: 0;
      }

      > p:first-child {
        @include font-size(46px);
        color: $primary;
        font-family: fieldwork, $font-family-sans-serif;
        font-weight: 600;
        line-height: (56px / $font-size-base) * 1rem;
        //margin-top: 2rem;
        margin-bottom: 3rem;
        position: relative;
        text-align: left;
        z-index: 1;

        &:before {
          //bottom: 1.75rem;
          color: $primary;
          content: "\201C";
          display: block;
          font-size: 150px;
          font-weight: 300;
          left: -4.5rem;
          //line-height: 0;
          opacity: 0.5;
          position: absolute;
          // top: 0;
          top: 2.5rem;
          // transform: rotate(180deg);
          z-index: 1;
        }

        &:after {
          bottom: 1.75rem;
          color: $primary;
          content: "\201D";
          display: inline-block;
          font-size: 150px;
          font-weight: 300;
          left: 1rem;
          line-height: 0;
          opacity: 0.5;
          position: relative;
          transform: rotate(180deg);
          z-index: 1;
        }
      }

      > p:last-child {
        @include static-font-size(18px);
        color: $primary;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 2rem;
        position: relative;
        z-index: 1;
      }

      strong,b {
        font-weight: 700;
      }

      // ul {
      //     margin-bottom: 0;
      // }

      // @include media-breakpoint-up(md) {
      //   line-height: 2.625rem;
      // }

      @include media-breakpoint-up(fhd) {
        @include make-col-ready;
        @include make-col(8);
        //@include make-col-offset(1);
      }
    }

    .two-cols {
      column-count: 2;
      column-gap: 4em;
      column-width: 360px;
      margin-bottom: 2rem;

      > p:last-child {
        margin-bottom: 0;
      }
    }

    ul:not(.list-unstyled) {
        list-style: none;
        margin-bottom: 2.5rem;
        padding-left: 2rem;

        li {
          margin-bottom: 1rem;

          // @include media-breakpoint-up(fhd) {
          //   max-width: 62.5%;
          // }

          &::before {
              content: "•";
              color: $primary;
              display: inline-block;
              margin-left: -2rem;
              opacity: 0.4;
              // transform: scale(1.25);
              // transform: translateY(-1px);
              width: 2rem;
          }
        }
    }
    // ●

    ol {
      counter-reset: li; /* Initiate a counter */
      margin-bottom: 1.75rem;
      margin-left: 0;
      padding-left: 1.25em;
    }

    ol > li {
        //color: $light-grey;
        display: block;
        float: none;
        //font-weight: 400;
        position: relative;
        //margin: 30px 0 16px 1em; /* Give each list item a left margin to make room for the numbers */
        margin: 0;
        padding: 0;
        list-style: none;
        text-indent: none;
    }

    ol > li:before {
        box-sizing: border-box;
        color: $primary;
        content: counter(li, decimal) ". "; /* Use the counter as content */
        counter-increment: li; /* Increment the counter by 1 */
        //font-size: 16px;
        //font-weight: 600;
        /* Position and style the number */
        //outline: 1px solid #f00;
        // opacity: 0.4;
        position: absolute;
        top: 0;
        left: -1.25em;
        /* Some space between the number and the content in browsers that support
            generated content but not positioning it (Camino 2 is one example) */
        line-height: ($line-height-base / $font-size-base) * 1rem;
        margin: 0;
        padding: 0;
        text-align: left;
        width: 1em;
    }

    ol > li > p {
      // @include font-size(16px);
      // color: $grey;
      margin: 0;
    }

    a {
        @include media-breakpoint-down(sm) {
            word-break: break-word;
        }
    }

    img {
        margin-top: 1rem;
        margin-bottom: 1rem;
        max-width: 100%;
        height: auto;
    }

  .alignright,
  .alignleft,
  .aligncenter,
  .alignnone {
    margin: 2rem auto;
    max-width: 100%;

    @include media-breakpoint-down(sm) {
      display: block;
      text-align: center;
    }
  }
  .alignleft {
    @include media-breakpoint-down(md) {
      clear: both;
    }
    @include media-breakpoint-up(md) {
      float: left;
      margin: 1rem;
      margin-left: 0;
    }
  }
  .alignright {
    @include media-breakpoint-down(md) {
      clear: both;
    }
    @include media-breakpoint-up(md) {
      float: right;
      margin: 1rem;
      margin-right: 0;
    }
  }
  .aligncenter {
    @include media-breakpoint-up(md) {
      margin: 3rem auto;
      display: block;
    }
  }
  .alignnone {
    @include media-breakpoint-up(md) {
      + img {
        margin-left: 1rem;
      }
    }
  }

  figure .wp-caption-text {
    font-size: 0.85rem;
    color: $primary;
  }
}

// .single-programme .content {
//   .highlighted-text {
//     @include font-size(24px);
//     color: $dark-grey;
//     font-family: "neusa-next-std", $font-family-sans-serif;
//     font-weight: 400;
//     line-height: (34px / $font-size-base) * 1rem;
//     text-transform: uppercase;
//   }

//   >p:first-of-type {
//       @include font-size(20px);
//       color: $dark-grey;
//       font-family: "Avenir LT W01_65 Medium1475532", $font-family-sans-serif;
//       font-weight: 400;
//       line-height: (30px / $font-size-base) * 1rem;
//   }
// }

// .single-issue .content {
//   >p:first-of-type {
//     font-weight: 500;
//     text-transform: uppercase;
//   }
// }
