.feature-block {
  margin-bottom: 60px;
  position: relative;
  z-index: 1;

  @include media-breakpoint-up(md) {
    margin-bottom: 122px;
  }

  @include media-breakpoint-up(lg) {
    &:nth-child(2n) {
      .row > .col-lg-5:nth-child(1) {
        order: 2;
      }

      picture {
        padding-bottom: (550 / (523 / 100)) * 1%;
        max-width: 523px;
      }
    }
  }

  picture,
  img {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  picture {
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    padding-bottom: (550 / (470 / 100)) * 1%;
    z-index: 1;
  }

  img {
    position: absolute;
    width: 100%;
  }

  .far {
    color: $grey;
    font-size: 42px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 47px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $primary;
    font-size: 38px;
  }

  h4 {
    font-size: 20px;
    margin-bottom: 7.5px;
  }

  .btn-link {
    display: inline-block;
    margin-top: 0;

    @include media-breakpoint-up(md) {
      margin-top: 50px;
    }

    i {
      font-size: 18px;
    }
  }
}

.feature-block__items {
  .row {
    margin-bottom: 16.5px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $secondary;
    font-size: 20px;
    margin-bottom: 7.5px;
  }
}
