.link-block {
  align-items: center;
  background-color: #e4f5fe;
  border-radius: 5px;
  color: #1f2041;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 21px;
  padding: 40px;
  transition: all 0.3s;

  @media (min-width: 768px) {
    padding: 80px 40px;
  }

  @media (max-width: 991px) {
    padding: 40px 30px;
  }

  @media (max-width: 767px) {
    flex-direction: row;
  }

  &:hover {
    background-color: #2cab6c;
    color: white;

    i,
    span {
      color: white;
    }
  }

  i {
    font-size: 52px;
    margin-bottom: 18px;
    transition: inherit;
    @media (max-width: 767px) {
      font-size: 34px;
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  span {
    font-size: 24px;
    transition: inherit;
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
}
