@media screen {
  .js {

    @include media-breakpoint-up(lg) {
      .fromLeft {
        opacity: 0.0;
        transform: translate3d(-128px, 0, 0);
      }

      .fromRight {
        opacity: 0.0;
        transform: translate3d(128px, 0, 0);
      }

      .slideUp {
        opacity: 0.0;
        transform: translate3d(0, 128px, 0);
      }
    }

    .slide {
      animation: slide;
      animation-delay: 0;
      animation-duration: 500ms;
      animation-timing-function: $material-decelerate;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-play-state: running;
    }

    @keyframes slide {
      to {
        opacity: 1.0;
        transform: translate3d(0,0,0);
      }
    }

    @keyframes wipe {
      to {
        transform: scale3d(0, 1, 1);
        will-change: unset;
      }
    }

    @keyframes slide {
      to {
        opacity: 1.0;
        transform: translate3d(0,0,0);
      }
    }

    .fade-in {
      opacity: 0.0;
    }

    // .is-visible {
    //   animation: fadein;
    //   animation-delay: 300ms;
    //   animation-duration: 600ms;
    //   animation-timing-function: $material-decelerate;
    //   animation-fill-mode: forwards;
    //   animation-iteration-count: 1;
    //   animation-play-state: running;
    // }

    @keyframes fadein {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    // .slide-x:nth-child(even) {
    //   animation-delay: 150ms; /* So they look staggered */
    // }

    // @keyframes slide-x {
    //   to {
    //     opacity: 1;
    //     transform: translateX(0);
    //   }
    // }

    /* So they look staggered */
    // @include media-breakpoint-only(md) {
    //   // every other column
    //   .product-col:nth-child(2n+2) .slide-y {
    //     animation-delay: 100ms;
    //   }
    // }

    // @include media-breakpoint-between(lg, qhd) {
    //   // every 2nd and 3rd column
    //   .product-col:nth-child(3n+2) .slide-y {
    //     animation-delay: 100ms;
    //   }

    //   .product-col:nth-child(3n+3) .slide-y {
    //     animation-delay: 200ms;
    //   }
    // }

    // @include media-breakpoint-up(qhd) {
    //   // every 2nd, 3rd and 4th column
    //   .product-col:nth-child(4n+2) .slide-y {
    //     animation-delay: 100ms;
    //   }

    //   .product-col:nth-child(4n+3) .slide-y {
    //     animation-delay: 200ms;
    //   }

    //   .product-col:nth-child(4n+4) .slide-y {
    //     animation-delay: 300ms;
    //   }
    // }
  }
}

// .slide-y:nth-child(3n+2) {
//   .post-div {
//       animation-delay: 200ms; /* So they look staggered */

//       .product-tile {
//           &:before {
//               animation-delay: 600ms !important;
//           }
//       }
//   }
// }
// .slide-y:nth-child(3n+3) {
//   .post-div {
//       animation-delay: 300ms; /* So they look staggered */

//       .product-tile {
//           &:before {
//               animation-delay: 800ms !important;
//           }
//       }
//   }
// }

// @keyframes slide-y-up {
//   to {
//       opacity: 0;
//       transform: translateY(0);
//   }
// }

// @keyframes slide-y-down {
//   to {
//       opacity: 1;
//       transform: translateY(100%);
//   }
// }
