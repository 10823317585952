#login {
  .login-username,
  .login-password {
    @extend .form-row;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #user_login,
  #user_pass {
    @extend .form-control;
    margin-bottom: 0 !important;
  }

  .button.button-primary {
    @extend .btn;
    @extend .btn-secondary;
    @extend .btn-lg;
  }

  .login-remember,
  .forgot-password {
    display: none;
  }
}

.login__form-container {
  margin-bottom: 2rem;
  @media (max-width: 991px) {
    padding-bottom: 2rem;
    border-bottom: 1px solid $lighter-grey;
  }
}
