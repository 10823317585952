.header {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $primary;
  // height: 110px;
  // padding-bottom: 8px;
  // padding-top: 8px;
  // position: fixed;
  position: relative;
  transform: translate3d(0, -100%, 0);
  transition: transform 300ms $material-decelerate;
  width: 100%;

  &.float {
    position: fixed;
    z-index: $zindex-fixed;
  }

  &.show {
    transform: translate3d(0, 0, 0);
  }

  .cswr-logo {
    width: 30px;
    height: 50px;

    @include media-breakpoint-up(lg) {
      width: 60px;
      height: 43px;
    }
  }

  .logo-text-site {
  }
}

.header__inner {
  display: flex;
  align-items: center;
  margin-bottom: auto;
  margin-top: auto;
  height: 110px;
  max-height: 50px;
  width: 100%;
  transition: all 0.5s;

  @include media-breakpoint-up(lg) {
    max-height: 110px;
  }

  > .container {
    @include media-breakpoint-down(lg) {
      max-width: none;
    }
  }
}

.header.is-hidden {
  .header__inner {
    max-height: 50px;

    @include media-breakpoint-up(md) {
      max-height: 43px;
    }
  }

  .cswr-logo {
    width: 30px;
  }

  @include media-breakpoint-up(md) {
    .logo-text-group,
    .logo-text-site {
      opacity: 0;
    }

    .header-nav {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.menu-button-container {
  position: absolute;
  right: 15px;
  transform: rotate(180deg);

  .sidebar__menu-toggle.menu-button {
    transform: translateY(-100%);
  }
}

.login-menu-button {
  font-size: 20px;
  position: absolute;
  right: 70px;
  a {
    color: white;
  }
  @media screen and (max-width: 340px) {
    display: none !important;
  }
}

.page-template-contact {
  .header.is-not-top {
    box-shadow: 0px 1px 0px 0px rgba(255,255,255,1);
  }
}
