a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Avenir LT W01_85 Heavy1475544", $font-family-sans-serif;
  // font-weight: 700;
  transition: all 100ms $material-standard;
}

.angle-link {
  font-size: 20px;

  .fas {
    //font-size: 18px;
    margin-left: 0.25rem;
    position: relative;
    top: 1px;
  }
}
