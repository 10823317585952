// some button attributes are set by variables in _custom.scss

.btn {
  @include font-smooth;
  // @include static-font-size(18px);
  // @include xd-letter-spacing(25);
  // border-color: transparent;
  // border-radius: 5px;
  // line-height: (88px / $font-size-base) * 1rem;
  background-color: $secondary;
  color: white;
  max-width: 100%;
  white-space: nowrap;
  transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out;

  // i {
  //   margin-left: 1rem;
  // }
}

.btn-light {
  color: $primary;
}

.btn-wide {
  padding-left: 4rem;
  padding-right: 4rem;
}

.btn-transparent {
  background-color: transparent;
  border-color: $secondary;
  color: $secondary;
  font-weight: 600;

  &:hover, &:focus, &:active {
    background: $primary;
    border-color: $primary;
    color: #fff;
  }

}

.btn-primary {
  &:hover {
    background: #fff;
    border-color: $primary;
    color: $primary;
  }

  // &:active {
  //   background: #16471f;
  //   border-color: #16471f;
  //   color: #16471f;
  // }
}

.btn-secondary {
  &:hover, &:focus, &:active {
    background: $primary;
    border-color: $primary;
    color: #fff;
  }
}

.btn--ultra {
  height: 100%;
  width: 100%;
}

.btn-link {
  @include static-font-size(20px);
  background-color: transparent;
  border: 0;
  font-family: 'Avenir';
  font-weight: bold;
  text-transform: none;
}

a.dropdown-toggle {
  -webkit-appearance: unset !important;
}
