.split-block {
  margin-bottom: 60px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 47px;
  }

  h3 {
    color: $primary;
    font-size: 38px;
  }

  .far {
    color: $grey;
    font-size: 42px;
  }
}

.split-block__items {

  h4 {
    font-size: 20px;
    margin-bottom: 7.5px;
  }

  @extend .feature-block__items;
}
