.offices {
  background-color: $primary;
  color: white;
  margin-bottom: 83px;
  padding-bottom: 73px;
  padding-top: 65px;

  h2,
  h3 {
    color: inherit;
  }

  h2 {
    font-size: 38px;
    margin-bottom: 41px;
  }

  h3 {
    border-bottom: 1px solid $lighter-grey;
    font-size: 20px;
    margin-bottom: 23px;
    text-transform: uppercase;
    padding-bottom: 11px;
  }

  p {
    font-size: 20px;
  }
}

.office {
  margin-bottom: 33px;
}

.map {
  padding-bottom: 100%;
  position: relative;

  @include media-breakpoint-up(lg) {
    bottom: -73px;
    left: 0;
    // margin-top: -65px;
    // margin-bottom: -73px;
    // margin-right: calc(-50vw + 50%);
    // right: 36%;
    width: 50vw;
    // height: 100%;
    position: absolute;
    padding-bottom: 0;
    top: -65px;
  }

  @include media-breakpoint-up(xl) {
    // right: 70%;
  }

  #map {
    display: block;
    width: 100%;
    height: 100%;
    @include media-breakpoint-down(lg) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
