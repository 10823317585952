.cta-block {
  margin-bottom: 73px;
}

.cta-block__inner {
  border-top: 1px solid $lighter-grey;
  padding-top: 58px;
  text-align: center;

  h2 {
    @include font-size(38px);
    color: $primary;
    margin-bottom: 42px;
  }
}
