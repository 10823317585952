@import 'mixins';
@import 'a';

// h1,h2,h3,h4,h5,h6,blockquote,button,li,p,a,.btn,
// input[type='submit'],
// input[type='text'],
// input[type='email'],
// input[type='tel'],
// label,select,option,textarea,
// .font-smoothing {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// h1,
// .h1 {
//   @include font-size($h1-font-size);
// }

// h2,
// .h2 {
//   @include font-size($h2-font-size);

//   // @include media-breakpoint-down(md) {
//   //   @include static-font-size(3.6rem);
//   // }
// }

h3,
h4,
h5,
h6 {
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // font-family: Avenir;
  font-family: 'Avenir LT W01_65 Medium1475532', $font-family-sans-serif;
  font-weight: normal;
  //font-weight: 550;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.is-blue {
    color: $primary;
  }
}

// h4,
// .h4 {
//   margin-bottom: 1.5rem;
//   text-transform: none;
// }

// h5,
// .h5 {
//   margin-bottom: 1.5rem;
//   text-transform: none;
// }

// h6,
// .h6 {
//   margin-bottom: 1rem;
//   text-transform: none;
// }

p {
  &.is-large {
    @include font-size(28px);
  }
}

.intro {
  @include font-size(20px);
  font-family: 'Avenir LT W01_65 Medium1475532', $font-family-sans-serif;
  font-weight: normal;
  //font-weight: 550;
}

small {
  @include font-size(14px);
  text-transform: none;

  a {
    text-decoration: none;
  }
}

// p {
//   font-weight: 350;
// }

.page-title {
  @include font-size(60px);
  color: $primary;

  // @include media-breakpoint-up(xl) {
  //   margin-top: 65px;
  // }

  // @include media-breakpoint-up(fhd) {
  //   margin-top: 45px;
  // }
}

.note {
  color: $light-grey;
  // font-weight: 350;
}

// fix a bug in Edge
.list-unstyled > li {
  white-space: nowrap;
}
