@import './fetaure-block';

.feature-blocks {
  background-color: $ternary;
  position: relative;
  padding-top: 35px;

  &::before,
  &::after {
    background-image: url('../img/curve-top.svg');
    background-size: 100% 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    content: '';
    height: 170px;
    left: 0;
    position: absolute;
    right: 0;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
    transform: scaleY(-1);
    z-index: 0;
  }
}

.text-block + .feature-blocks {
  margin-bottom: 50px;

  @include media-breakpoint-up(md) {
    margin-bottom: 176px;
  }
}
