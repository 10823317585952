.banner {
  margin-top: 40px;
  position: relative;

  @include media-breakpoint-up(lg) {
    margin-top: 110px;
  }

  &::before {
    background-image: url('../img/curve-top.svg');
    background-size: 100% 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    bottom: 0;
    content: '';
    height: 186px;
    left: 0;
    position: absolute;
    transform: scaleY(-1);
    right: 0;
    z-index: 1;

    @media (max-width: 991px) {
      display: none;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: white;
  }

  p {
    @include static-font-size(20px);
  }

  .flickity-viewport {
    overflow: hidden;
    transition: all 0.5s;

    @media (min-width: 1368px) {
      padding-bottom: (685 / (1600 / 100)) * 1%;
    }
  }
}

.banner__inner {
  @include media-breakpoint-up(lg) {
    overflow: hidden;
    height: 580px;
  }

  @media (min-width: 1368px) {
    padding-bottom: (685 / (1600 / 100)) * 1%;
  }

  ul {
    @media (min-width: 1368px) {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.banner__slide {
  background-color: $secondary;
  margin-right: 30px;
  width: 100%;

  picture {
    display: block;
    // padding-bottom: (585 / (1600 / 100)) * 1%;
    height: 90vh;
    position: relative;

    &.no-image {
      &::after {
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      height: 580px;
    }

    @media (min-width: 1368px) {
      height: auto;
      padding-bottom: (685 / (1600 / 100)) * 1%;
    }

    &::after {
      background: linear-gradient(
        180deg,
        rgba(69, 70, 97, 0) 0%,
        rgba(69, 70, 97, 1) 100%
      );
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    width: 100%;
  }

  .container {
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transition: all 0.5s;
    transform: translateY(-50%);

    @include media-breakpoint-up(lg) {
      top: 5.8125vw;
      transform: none;
    }
  }
}

.banner--no-image {
  background-color: $secondary;
  padding: 80px 0 60px;

  @include media-breakpoint-up(lg) {
    padding: 0;
    min-height: 650px;
  }

  h1 {
    color: white;
  }

  .container {
    @include media-breakpoint-up(lg) {
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transition: all 0.5s;
      transform: translateY(-50%);
    }
  }
}

.banner--secure {
  align-items: center;
  background-color: #e4f5fe;
  display: flex;
  margin-top: 66px;
  margin-bottom: 72px;
  min-height: 300px;
  padding-top: 55px;
  padding-bottom: 55px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $primary;
  }

  h1 {
    font-size: 50px;
  }

  p {
    font-size: 18px;
  }

  &::before {
    display: none;
  }

  .banner__caption {
    margin-bottom: 36px;
  }

  .row--banner-jobs {
    @media (min-width: 992px) {
      .col-lg-4,
      .col-lg-5,
      .col-lg-2 {
        padding-left: 0;
        padding-right: 0;

        &:first-of-type {
          padding-left: 15px;

          .Select {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }

        &:last-of-type {
          padding-right: 15px;

          .Select {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
  }

  + .FilterBar {
    margin-top: -72px;
  }
}
